import { useState, useEffect } from 'react';

export const useLocalStorage = <T,>(key: string, initialValue: T) => {
  // Estado inicial, intenta cargar desde localStorage
  const [value, setValue] = useState<T>(() => {
    try {
      const storedValue = localStorage.getItem(key);
      return storedValue ? JSON.parse(storedValue) : initialValue;
    } catch (error) {
      console.error("Error al leer de localStorage:", error);
      return initialValue;
    }
  });

  // Actualiza localStorage cada vez que el valor cambia
  useEffect(() => {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.error("Error al guardar en localStorage:", error);
    }
  }, [key, value]);

  return [value, setValue] as const; // Devuelve el estado y el setter
}
