import React, { useState, useCallback } from "react";
import {
  AppBar as MuiAppBar,
  Chip,
  Avatar,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import {
  Add as AddIcon,
  ArrowCircleLeftSharp,
  Create as CreateIcon,
  Close as CloseIcon,
  Menu as MenuIcon,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import "../../css/appbar.css";
import { DialogAdd, DialogChangePass, DialogCloseSession } from "../dialog";
import logoGif from "../../../images/animacion-logo.gif";
//imagenes
import logoExpo from "../../images/logo.png";
import { useRouterStore } from "../../../libs/stores";
import {
  COLOR_PRIMARY,
  COLOR_SECONDARY,
  NAVBAR_HEIGHT,
} from "../../../libs/constants";
import { useWidth } from "../../../libs/hooks";
// import { Offline, Online } from "react-detect-offline";
import { useNavigate } from "react-router-dom";

export const drawerWidth = 180;

const AppBarElement = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open, small }) => ({
  height: `57px`,
  minHeight: `57px`,
  maxHeight: `57px`,
  zIndex: 1001,
  background: COLOR_PRIMARY,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: small === "true" ? 0 : drawerWidth,
    width: small === "true" ? "100%" : `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const AppBar = ({ authTokens, handleDrawerOpen, open }) => {
  const width = useWidth();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openAdd, setOpenAdd] = useState(false);
  const [openSession, setOpenSession] = useState(false);
  const [openPassNew, setOpenPassNew] = useState(false);
  const avatar = useRouterStore((state) => state.avatar);
  
  const openUser = Boolean(anchorEl);

  const handleMenuUser = (event) => {
    if (anchorEl === null) {
      setAnchorEl(event.currentTarget);
      return;
    }
    setAnchorEl(null);
  };

  const handleCloseUser = () => {
    setAnchorEl(null);
  };

  const handleCloseAdd = useCallback(() => {
    setOpenAdd(false);
  }, [openAdd]);

  const handleClosePassNew = useCallback(() => {
    setOpenPassNew(false);
  }, [openPassNew]);

  const handleCloseSession = useCallback(() => {
    setOpenSession(false);
  }, [openSession]);

  return (
    <React.Fragment>
      <AppBarElement
        position="fixed"
        open={open}
        small={width === "xs" ? true : false}
      >
        <Toolbar className="toolbar">
          <div>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                borderRadius: 2,
                border:"1px solid gainsboro",
                height: 32,
                marginRight: 5,
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Button
              onClick={() => navigate(-1)}
              size="large"
              color="inherit"
              variant="outlined"
              sx={{
                position: "relative",
                left: open ? "-19px" : width === "xs" ? "-30px" : "-2px",
                marginRight:"-50px",
              }}
            >
              <ArrowCircleLeftSharp />
            </Button>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              flexWrap: "nowrap",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <img src={logoExpo} alt="EXPOBANANA" className="logo" />
            <ElChip/>
          </div>
          <div className="avatar-contain">
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenuUser}
              color="inherit"
            >
              <Avatar
                className="avatar"
                alt={authTokens.nombre}
                src={avatar ?? logoGif}
                // src={`/avatares/${authTokens.usuario}.jpg`}
              />
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                open={openUser}
                onClose={handleCloseUser}
              >
                <MenuItem
                  onClick={() => {
                    if (authTokens) {
                      if (authTokens.tipo !== "EJECUTIVA") {
                        setOpenAdd(true);
                        setAnchorEl(null);
                      } else {
                        alert("No tiene este Acceso");
                        setAnchorEl(null);
                      }
                    }
                  }}
                >
                  <AddIcon style={{ color: "LightGreen" }} /> Crear Usuario
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setOpenPassNew(true);
                    setAnchorEl(null);
                  }}
                >
                  <CreateIcon style={{ color: "Teal" }} /> Cambiar Clave
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setOpenSession(true);
                    setAnchorEl(null);
                  }}
                >
                  <CloseIcon style={{ color: "Red" }} /> Cerrar Sesion
                </MenuItem>
              </Menu>
            </IconButton>
          </div>
        </Toolbar>
      </AppBarElement>
      <DialogAdd open={openAdd} handleClose={handleCloseAdd} />
      <DialogCloseSession open={openSession} handleClose={handleCloseSession} />
      <DialogChangePass
        open={openPassNew}
        handleClose={handleClosePassNew}
        authTokens={authTokens}
      />
    </React.Fragment>
  );
};

export const ElChip = ()=>{
  const section = useRouterStore((state) => state.section);
  if(!section) return null;
  return(
    <Chip title={section} color="secondary" size="small" label={section} sx={{fontWeight:"bold"}}/>
  )
}
