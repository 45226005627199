import { useQuery } from "@tanstack/react-query";
import { clientesApi } from "./axios";
import { 
    DataI,
    MigrationsI, 
    PropsI, 
    ServiceResponseI,
    ClientesI,
    ClientI,
    ObjPostI
} from '../../interfaces';
import {
    ValidateCurrentToken, 
    LogoutSystem, 
} from '../../helpers';
import { AxiosError } from 'axios';
import { CreateClientDataI } from "../../../components/clients/interface";

const getAllClients = async ({
    pagination, 
    sorting, 
    globalFilter
}:PropsI):Promise<ServiceResponseI<DataI<ClientesI>>> => {

    try{ 
        
        const validateToken = ValidateCurrentToken();

        if(!validateToken) throw new Error('TOKEN INVALIDO');

        const params = new URLSearchParams();

        params.append('page', (pagination!.pageIndex + 1).toString());
        params.append('limit', pagination!.pageSize.toString());
        globalFilter && params.append('search', globalFilter);
        sorting && params.append('sorting', JSON.stringify(sorting));
        
        //headers: { 'Authorization': validateToken
        const { data } = await clientesApi.get<ServiceResponseI<DataI<ClientesI>>>('',{ params, headers: { 'Authorization': validateToken}});
        return data;
    
    }catch(err){
        const obj = (err as AxiosError).response;

        if(obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }

        throw new Error((obj?.data as Error).message)
    }

    
}

export const getAllClientsNoPagination = async ():Promise<ServiceResponseI<ClientI[]>> =>{
    try{ 
        const validateToken = ValidateCurrentToken();
        if(!validateToken) throw new Error('TOKEN INVALIDO');


        const { data } = await clientesApi.get<ServiceResponseI<ClientI[]>>(`/all-no-pagination`,{ headers: { 'Authorization': validateToken}});
        
        return data;

    }catch(err){
        const obj = (err as AxiosError).response;
        if(obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const getClientes = async (urlConcat:string | null) => {
    
    if(urlConcat){
        const { data } = await clientesApi.get(urlConcat);
        return data;
    }

    const { data } = await clientesApi.get(''); 
    return data;
    
}

export const useClientesAll = ({
    sorting,
    pagination,
    globalFilter
}:PropsI)=>{

    
    const clientesQueryAll = useQuery(
        ["clientes-all", { pagination, sorting, globalFilter }],
        ()=>getAllClients({ pagination, sorting, globalFilter }),
        {
            refetchOnWindowFocus:false,
            keepPreviousData:true
        }
    );


    return {
        clientesQueryAll,
    };
}

export const useClientes = (urlConcat:string | null)=>{

    const clientesQuery = useQuery(
        ["clients"],
        ()=>getClientes(urlConcat),
        {
            refetchOnWindowFocus:false
        }
    );


    return {
        clientesQuery
    };
}

export const clientsPost = async<T>(getForms: T): Promise<ServiceResponseI<ObjPostI>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await clientesApi.post<ServiceResponseI<ObjPostI>>("", getForms, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const clientsPut = async<T>(id: number, updateForms: T): Promise<ServiceResponseI<string>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await clientesApi.put<ServiceResponseI<string>>(`/${id}`, updateForms, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const clientById = async (id: number): Promise<ServiceResponseI<ClientesI | CreateClientDataI | any>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await clientesApi.get<ServiceResponseI<ClientesI | CreateClientDataI | any>>(`/${id}`, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}