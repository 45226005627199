import React from "react";
import {
    Navigate,
} from "react-router-dom";


const SystemCredentialComponent = React.lazy(() => import("../../../../../components/system/page"));

export const systemRoute = [
    {
        path: 'credentials',
        element: <SystemCredentialComponent />
    },
    
    {
        path: '',
        element: <Navigate replace to="/options/system/credentials" />
    },
];