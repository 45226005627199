import React from "react";
import { GenericPage } from "../../../../../pages";
import { extrasEmptyOneRoute } from "./extras-vacio-1.route";
import { extrasEmptyTwoRoute } from "./extras-vacio-2.route";
import { extrasEmptyThreeRoute } from "./extras-vacio-3.route";
import { extrasFullOneRoute } from "./extras-full-1.route";
import { extrasFullTwoRoute } from "./extras-full-2.route";
import { extrasFullThreeRoute } from "./extras-full-3.route";



export const extrasRoute = [
  {
    path: 'empty-one',
    element: <GenericPage />,
    children:extrasEmptyOneRoute,
  },
  {
    path: 'empty-two',
    element: <GenericPage />,
    children:extrasEmptyTwoRoute,
  },
  {
    path: 'empty-three',
    element: <GenericPage />,
    children:extrasEmptyThreeRoute,
  },
  {
    path: 'full-one',
    element: <GenericPage />,
    children:extrasFullOneRoute,
  },
  {
    path: 'full-two',
    element: <GenericPage />,
    children:extrasFullTwoRoute,
  },
  {
    path: 'full-three',
    element: <GenericPage />,
    children:extrasFullThreeRoute,
  },
  
]