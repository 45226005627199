export const AddonBefore = ({ label, width }: { label: string; width: string }) => {
    return (
      <p
        style={{
          fontSize: width === "xs" ? "10px" : "11px",
          fontWeight: "bolder",
          width: "110px",
          textWrap: "wrap",
          textAlign: "center",
          lineHeight: 1.2,
        }}
      >
        {label}
      </p>
    );
  };
  