import React, { useState } from 'react';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from '@mui/material';

export const DialogAdd = ({ open, handleClose }) => {

    const [subscripcion, setSubscripcion] = useState({});

    const handleSubscripcion = (e) => {
        setSubscripcion({ ...subscripcion, [e.target.name]: e.target.value })
    };

    const handleAdd = () => {
        fetch('fetch_addlogin', {
            method: 'POST',
            body: JSON.stringify(subscripcion),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json())
            .then(result => {
                if (result.message) {
                    alert('Agregado')
                    setSubscripcion({});
                    handleClose();
                } else {
                    alert(result.status);
                }
            });
    };
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title" >
            <DialogTitle id="form-dialog-title">Creación de Usuario</DialogTitle>
            <DialogContent>
                <TextField
                    name='usuario'
                    margin="dense"
                    label="Usuario"
                    fullWidth
                    variant='outlined'
                    value={subscripcion.usuario || ''}
                    onChange={handleSubscripcion}
                />
                <TextField
                    name='nombre'
                    margin="dense"
                    label="Nombre"
                    fullWidth
                    variant='outlined'
                    value={subscripcion.nombre || ''}
                    onChange={handleSubscripcion}
                />
                <TextField
                    name='email'
                    margin="dense"
                    label="Email"
                    type='email'
                    fullWidth
                    variant='outlined'
                    value={subscripcion.email || ''}
                    onChange={handleSubscripcion}
                />
                <TextField
                    name='coordinador'
                    margin="dense"
                    label="Coordinador"
                    fullWidth
                    variant='outlined'
                    value={subscripcion.coordinador || ''}
                    onChange={handleSubscripcion}
                />
                <TextField
                    name='tipo'
                    margin="dense"
                    label="Tipo"
                    fullWidth
                    variant='outlined'
                    value={subscripcion.tipo || ''}
                    onChange={handleSubscripcion}
                />
                <TextField
                    name='area'
                    margin="dense"
                    label="Area"
                    fullWidth
                    variant='outlined'
                    value={subscripcion.area || ''}
                    onChange={handleSubscripcion}
                />
                <TextField
                    name='pass'
                    margin="dense"
                    label="Password"
                    fullWidth
                    variant='outlined'
                    value={subscripcion.pass || ''}
                    onChange={handleSubscripcion}
                />

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="error" variant="outlined">
                    Cancel
                </Button>
                <Button onClick={handleAdd} color="primary" variant="contained">
                    Agregar
                </Button>
            </DialogActions>
        </Dialog>
    )
}