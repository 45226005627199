
import React from "react";
import {
  Navigate,
} from "react-router-dom";


const DatasEmptyLabelList = React.lazy(() => import("../../../../../components/empty-label/components/list/page"));


export const datasEmptyLabelRoutes = [
  {
    path: 'list',
    element: <DatasEmptyLabelList />,
  },
  {
    path: '',
    element: <Navigate replace to="/datas/empty-labels/list" />
  },
];