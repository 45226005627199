import React from "react";
import {
    Navigate,
} from "react-router-dom";

const CountryListComponent = React.lazy(() => import("../../../../../components/country/components/list/page"));
const CountryAddComponent = React.lazy(() => import("../../../../../components/country/components/add/page"));

export const countryRoute = [
    {
        path: 'list',
        element: <CountryListComponent />
    },
    {
        path: 'add',
        element: <CountryAddComponent />
    },
    {
        path: 'edit',
        element: <Navigate to="/models/countries/add" />
    },
    {
        path: 'edit/:id',
        element: <CountryAddComponent />
    },
    {
        path: '',
        element: <Navigate replace to="/models/countries/list" />
    },
];