import React from "react";
import { GenericPage } from "../../../../../pages";

import { datasStampsRoutes } from "./datas-stamps.route";
import { datasEmptyLabelRoutes } from "./datas-empty-labels";
import { datasThermographerRoutes } from "./datas-thermographer";
import { datasUploadsRoutes } from "./datas-uploads.route";
import { datasVentolerasRoutes } from "./datas-ventolera.route";

export const datasRoute = [
  {
    path: 'stamps',
    element: <GenericPage />,
    children: datasStampsRoutes,
  },
  {
    path: 'empty-labels',
    element: <GenericPage />,
    children: datasEmptyLabelRoutes,
  },
  {
    path: 'thermographer',
    element: <GenericPage />,
    children: datasThermographerRoutes,
  },
  {
    path: 'ventolera',
    element: <GenericPage />,
    children: datasVentolerasRoutes,
  },
  {
    path: 'uploads',
    element: <GenericPage />,
    children: datasUploadsRoutes,
  },
]