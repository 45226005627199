import { useQuery } from "@tanstack/react-query";
import { workflowContainerFullApi } from "./axios";
import {
  DataI,
  ServiceResponseI,
  PropsWorkflowContainerFullI,
  WorkflowContainerFullI,
  ObjPostI,
} from "../../interfaces";
import { ValidateCurrentToken, LogoutSystem } from "../../helpers";
import { AxiosError } from "axios";

const getWorkflowContainerFulls = async ({
  pagination,
  sorting,
  globalFilter,
  producer,
  farm,
  client
}: PropsWorkflowContainerFullI): Promise<
  ServiceResponseI<DataI<WorkflowContainerFullI>>
> => {
  try {
    const validateToken = ValidateCurrentToken();

    if (!validateToken) throw new Error("TOKEN INVALIDO");

    const params = new URLSearchParams();

    params.append("page", (pagination!.pageIndex + 1).toString());
    params.append("limit", pagination!.pageSize.toString());
    params.append("search", globalFilter ?? "");
    params.append("sorting", JSON.stringify(sorting ?? []));
    client && params.append("client", client.toString());
    producer && params.append("producer", producer.toString());
    farm && params.append("farm", farm.toString());
    
    const { data } = await workflowContainerFullApi.get<
      ServiceResponseI<DataI<WorkflowContainerFullI>>
    >("", { params, headers: { Authorization: validateToken } });
    return data;
  } catch (err) {
    console.log(err);
    const obj = (err as AxiosError).response;

    if (obj?.status === 401) {
      console.error(obj.statusText);
      LogoutSystem();
    }

    throw new Error((obj?.data as Error).message);
  }
};

export const getAllWorkflowContainerFulls = async (): Promise<
  ServiceResponseI<WorkflowContainerFullI[]>
> => {
  try {
    const validateToken = ValidateCurrentToken();
    if (!validateToken) throw new Error("TOKEN INVALIDO");

    const { data } = await workflowContainerFullApi.get<
      ServiceResponseI<WorkflowContainerFullI[]>
    >(`/all-no-pagination`, { headers: { Authorization: validateToken } });

    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      console.error(obj.statusText);
      LogoutSystem();
    }
    throw new Error((obj?.data as Error).message);
  }
};

export const workflowContainerFullPost = async <T>(
  getForms: T
): Promise<ServiceResponseI<ObjPostI>> => {
  try {
    const validateToken = ValidateCurrentToken();
    if (!validateToken) throw new Error("TOKEN INVALIDO");
    const { data } = await workflowContainerFullApi.post<
      ServiceResponseI<ObjPostI>
    >("", getForms, { headers: { Authorization: validateToken } });
    return data;
  } catch (err: any) {
    if (err.code === "ERR_NETWORK") {
      return {
        data: {
          id: 0,
          alert: "Se guardará y notificará, cuando haya conexión a Internet",
          variant: "warning",
        },
        res: {} as any,
        req: {} as any,
      };
    }
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      console.error(obj.statusText);
      LogoutSystem();
    }
    throw new Error((obj?.data as Error).message);
  }
};

export const workflowContainerFullPut = async <T>(
  id: number,
  updateForms: T
): Promise<ServiceResponseI<string>> => {
  try {
    const validateToken = ValidateCurrentToken();
    if (!validateToken) throw new Error("TOKEN INVALIDO");
    const { data } = await workflowContainerFullApi.put<
      ServiceResponseI<string>
    >(`/${id}`, updateForms, { headers: { Authorization: validateToken } });
    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      console.error(obj.statusText);
      LogoutSystem();
    }
    throw new Error((obj?.data as Error).message);
  }
};

export const workflowContainerFullById = async (
  id: number
): Promise<ServiceResponseI<WorkflowContainerFullI>> => {
  try {
    const validateToken = ValidateCurrentToken();
    if (!validateToken) throw new Error("TOKEN INVALIDO");
    const { data } = await workflowContainerFullApi.get<
      ServiceResponseI<WorkflowContainerFullI>
    >(`/${id}`, { headers: { Authorization: validateToken } });
    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      console.error(obj.statusText);
      LogoutSystem();
    }
    throw new Error((obj?.data as Error).message);
  }
};

export const workflowContainerFullByContainer = async (
  container: string
): Promise<ServiceResponseI<WorkflowContainerFullI>> => {
  try {
    const validateToken = ValidateCurrentToken();
    if (!validateToken) throw new Error("TOKEN INVALIDO");
    const { data } = await workflowContainerFullApi.get<
      ServiceResponseI<WorkflowContainerFullI>
    >(`/by-container/${container}`, {
      headers: { Authorization: validateToken },
    });
    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      console.error(obj.statusText);
      LogoutSystem();
    }
    throw new Error((obj?.data as Error).message);
  }
};

export const workflowContainerFullForCPByContainer = async (
  container: string
): Promise<ServiceResponseI<WorkflowContainerFullI>> => {
  try {
    const validateToken = ValidateCurrentToken();
    if (!validateToken) throw new Error("TOKEN INVALIDO");
    const { data } = await workflowContainerFullApi.get<
      ServiceResponseI<WorkflowContainerFullI>
    >(`/by-container-for-checkpoint/${container}`, {
      headers: { Authorization: validateToken },
    });
    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      console.error(obj.statusText);
      LogoutSystem();
    }
    throw new Error((obj?.data as Error).message);
  }
};

export const workflowContainerFullDelete = async (
  id: number
): Promise<ServiceResponseI<string>> => {
  try {
    const validateToken = ValidateCurrentToken();
    if (!validateToken) throw new Error("TOKEN INVALIDO");
    const { data } = await workflowContainerFullApi.delete<
      ServiceResponseI<string>
    >(`/${id}`, { headers: { Authorization: validateToken } });
    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      console.error(obj.statusText);
      LogoutSystem();
    }
    throw new Error((obj?.data as Error).message);
  }
};

export const useWorkflowContainerFulls = ({
  sorting,
  pagination,
  globalFilter,
  producer,
  farm
}: PropsWorkflowContainerFullI) => {
  const workflowContainerFullsQuery = useQuery(
    ["workflowContainerFulls", { pagination, sorting, globalFilter, producer, farm  }],
    () => getWorkflowContainerFulls({ pagination, sorting, globalFilter, producer, farm  }),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  return {
    workflowContainerFullsQuery,
  };
};

export const generateWfFullPdfReportbyId = async (
  id: number
): Promise<ServiceResponseI<any>> => {
  try {
    // const validateToken = ValidateCurrentToken();
    // if (!validateToken) throw new Error("TOKEN INVALIDO");

    const { data } = await workflowContainerFullApi.get<ServiceResponseI<any>>(
      `/generate-pdf-report/${id}`,
      // { headers: { Authorization: validateToken } }
    );

    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      console.error(obj.statusText);
      LogoutSystem();
    }
    throw new Error((obj?.data as Error).message);
  }
};

export const workflowFullSendReportById = async <T>(
  getForms: T
): Promise<ServiceResponseI<string>> => {
  try {
    const validateToken = ValidateCurrentToken();
    if (!validateToken) throw new Error("TOKEN INVALIDO");
    const { data } = await workflowContainerFullApi.post<
      ServiceResponseI<string>
    >(`/send-report-by-workflow-id`, getForms, {
      headers: { Authorization: validateToken },
    });
    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      LogoutSystem();
    }
    throw new Error((obj?.data as Error).message);
  }
};
