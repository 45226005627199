import { useQuery } from "@tanstack/react-query";
import { workflowContainerApi } from "./axios";
import { 
    DataI,
    ServiceResponseI,
    PropsWorkflowContainerI,
    WorkflowContainerI,
    ObjPostI,
    VideosWorkflowContainerI,
    VideosWorkflowContainerCompleteI,
    WorkflowAllDataI
} from '../../interfaces';
import {
    ValidateCurrentToken,
    LogoutSystem, 
} from '../../helpers';
import { AxiosError } from 'axios';


const getWorkflowContainers = async ({
    pagination, 
    sorting, 
    globalFilter,
    client,
    user,
    producer,
    farm,
}:PropsWorkflowContainerI):Promise<ServiceResponseI<DataI<WorkflowContainerI>>>  => {

    try{ 
        
        const validateToken = ValidateCurrentToken();

        if(!validateToken) throw new Error('TOKEN INVALIDO');

        const params = new URLSearchParams();

        params.append('page', (pagination!.pageIndex + 1).toString());
        params.append('limit', pagination!.pageSize.toString());
        params.append('search', globalFilter ?? '');
        params.append('sorting', JSON.stringify(sorting ?? []));
        client && params.append('client', client.toString());
        user && params.append('user', user.toString());
        producer && params.append("producer", producer.toString());
        farm && params.append("farm", farm.toString());
        //headers: { 'Authorization': validateToken
        const { data } = await workflowContainerApi.get<ServiceResponseI<DataI<WorkflowContainerI>>>('',{ params, headers: { 'Authorization': validateToken}});
        return data;
    
    }catch(err){
        console.log(err);
        const obj = (err as AxiosError).response;

        if(obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }

        throw new Error((obj?.data as Error).message)
    }

    
}

export const generateWfPdfReportbyId= async (id:number):Promise<ServiceResponseI<any>> =>{
    try{ 
        const validateToken = ValidateCurrentToken();
        if(!validateToken) throw new Error('TOKEN INVALIDO');


        const { data } = await workflowContainerApi.get<ServiceResponseI<any>>(`/generate-pdf-report/${id}`,{ headers: { 'Authorization': validateToken}});
        
        return data;

    }catch(err){
        const obj = (err as AxiosError).response;
        if(obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const getAllDatabyWorkflowId= async (id:number):Promise<ServiceResponseI<WorkflowAllDataI>> =>{
    try{ 
        const validateToken = ValidateCurrentToken();
        if(!validateToken) throw new Error('TOKEN INVALIDO');


        const { data } = await workflowContainerApi.get<ServiceResponseI<WorkflowAllDataI>>(`/get-all-data-by-workflow-id/${id}`,{ headers: { 'Authorization': validateToken}});
        
        return data;

    }catch(err){
        const obj = (err as AxiosError).response;
        if(obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const workflowSendReportById = async<T>(getForms: T): Promise<ServiceResponseI<string>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await workflowContainerApi.post<ServiceResponseI<string>>(`/send-report-by-workflow-id`, getForms, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const wfSendVideobyWhatsapp = async<T>(getForms: T): Promise<ServiceResponseI<string> > => {
    try {
        const { data } = await workflowContainerApi.post<ServiceResponseI<string>>("/send-video-by-whatsapp", getForms );
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const workflowContainerPost = async<T>(getForms: T): Promise<ServiceResponseI<ObjPostI> > => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await workflowContainerApi.post<ServiceResponseI<ObjPostI>>("", getForms, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const workflowContainerPut = async<T>(id: number, updateForms: T): Promise<ServiceResponseI<string>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await workflowContainerApi.put<ServiceResponseI<string>>(`/${id}`, updateForms, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const workflowContainerPutVideos = async<T>(id:number, url:string, updateForms: T): Promise<ServiceResponseI<string>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await workflowContainerApi.put<ServiceResponseI<string>>(`/${url}/${id}`, updateForms, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const workflowContainerChangeStatusVideos = async<T>(id:number): Promise<ServiceResponseI<string>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await workflowContainerApi.put<ServiceResponseI<string>>(`/change-status-video/${id}`, {}, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const workflowContainerById = async (id: number): Promise<ServiceResponseI<WorkflowContainerI>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await workflowContainerApi.get<ServiceResponseI<WorkflowContainerI>>(`/${id}`, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const workflowContainerVideosById = async (id: number): Promise<ServiceResponseI<VideosWorkflowContainerCompleteI>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await workflowContainerApi.get<ServiceResponseI<VideosWorkflowContainerCompleteI>>(`/videos/${id}`, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const workflowContainerDelete = async (id: number): Promise<ServiceResponseI<string>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await workflowContainerApi.delete<ServiceResponseI<string>>(`/${id}`, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}


export const useWorkflowContainers = ({
    sorting,
    pagination,
    globalFilter,
    client,
    user,
    producer,
    farm,
}:PropsWorkflowContainerI)=>{

    
    const workflowContainersQuery = useQuery(
        ["workflowContainers", { pagination, sorting, globalFilter, client, user, producer, farm }],
        ()=>getWorkflowContainers({ pagination, sorting, globalFilter, client, user, producer, farm }),
        {
            refetchOnWindowFocus:false,
            keepPreviousData:true
        }
    );

    return {
        workflowContainersQuery,
    };
}