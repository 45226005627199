import { Grid } from "@mui/material";
import { Divider } from "antd";

export const DividerComponent = ({ label }: { label: string }) => {
    return (
      <Grid item xs={12}>
        <Divider dashed orientation="left" style={{ borderColor: "#006400" }}>
          <p style={{ color: "green", fontSize: "12px", fontWeight: "bolder" }}>{label}</p>
        </Divider>
      </Grid>
    );
  };