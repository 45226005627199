import React from "react";
import { GenericPage } from "../../../../../pages";

import {
  resourcesReportsRoute
} from "./resources-reports.route";
import { clientRoute } from "./resources-client.route";
import { exportersRoute } from "./resources-exporters.route";
import { videosRoute } from "./resources-videos.route";



export const resourcesRoute = [
  {
    path: 'reports',
    element: <GenericPage />,
    children:resourcesReportsRoute,
  },
  {
    path: 'clients',
    element: <GenericPage />,
    children: clientRoute,
  },
  {
    path: 'exporters',
    element: <GenericPage />,
    children: exportersRoute,
  },
  {
    path: 'videos',
    element: <GenericPage />,
    children: videosRoute,
  },
]