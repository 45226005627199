import React from "react";
import {
  Navigate,
} from "react-router-dom";


const VentolerasList = React.lazy(() => import("../../../../../components/ventolera/components/list/page"));


export const datasVentolerasRoutes = [
  {
    path: 'list',
    element: <VentolerasList />,
  },
  {
    path: '',
    element: <Navigate replace to="/datas/ventolera/list" />
  },
]