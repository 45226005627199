import { createTheme } from '@mui/material/styles';
import { COLOR_PRIMARY, COLOR_SECONDARY } from '../constants';
// import ITCAvant from '../../resources/fonts/ITCAvantGardePro-Md.ttf';


export const creativeTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1200,
      lg: 1366, 
      xl: 1536,
      ab: 1600
    },
  },
    palette: {
      primary: {
        main: COLOR_PRIMARY,
      },
      secondary: {
        main: COLOR_SECONDARY,
      },
    },
    typography: {
      fontFamily: 'Itc, Arial',
      fontSize:11
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: `
          @font-face {
            fontFamily: 'Itc',
            fontStyle: 'normal',
            fontDisplay: 'swap',
            fontWeight: 400,
            unicodeRange:
              'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
          }`,
      },
    },
    // src: 
    //   local('ITC'),
    //   local('ITC-Regular'),
    //   url(${ITCAvant}) format('ttf')
    // ,
    
});

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true; // removes the `xs` breakpoint
    sm: true;
    md: true;
    lg: true;
    xl: true;
    ab: true; 
    // tablet: true;
    // laptop: true;
    // desktop: true;
  }
}