import React from "react";
import {
    Navigate,
} from "react-router-dom";

const ResourcesReportsListComponent = React.lazy(() => import("../../../../../components/reports/components/list/page"));


export const resourcesReportsRoute = [
    {
        path: 'list',
        element: <ResourcesReportsListComponent />,
    },
    {
        path: '',
        element: <Navigate replace to="/resources/reports/list" />
    },
];