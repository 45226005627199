import { useQuery } from "@tanstack/react-query";
import { clientProducerFarmApi } from "./axios";
import { 
    DataI,
    ServiceResponseI,
    PropsClientProducerFarmI,
    ClientProducerFarmI
} from '../../interfaces';
import {
    ValidateCurrentToken,
    LogoutSystem, 
} from '../../helpers';
import { AxiosError } from 'axios';


const getClientProducerFarms = async ({
    pagination, 
    sorting, 
    globalFilter,
    client,
    producer,
}:PropsClientProducerFarmI):Promise<ServiceResponseI<DataI<ClientProducerFarmI>>>  => {

    try{ 
        
        const validateToken = ValidateCurrentToken();

        if(!validateToken) throw new Error('TOKEN INVALIDO');

        const params = new URLSearchParams();

        params.append('page', (pagination!.pageIndex + 1).toString());
        params.append('limit', pagination!.pageSize.toString());
        params.append('search', globalFilter ?? '');
        params.append('sorting', JSON.stringify(sorting ?? []));
        client && params.append('client', client.toString());
        producer && params.append('producer', producer.toString());

        //headers: { 'Authorization': validateToken
        const { data } = await clientProducerFarmApi.get<ServiceResponseI<DataI<ClientProducerFarmI>>>('',{ params, headers: { 'Authorization': validateToken}});
        return data;
    
    }catch(err){
        console.log(err);
        const obj = (err as AxiosError).response;

        if(obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }

        throw new Error((obj?.data as Error).message)
    }

    
}

export const getAllClientProducerFarms = async (producer:number):Promise<ServiceResponseI<ClientProducerFarmI[]>> =>{
    try{ 
        const validateToken = ValidateCurrentToken();
        if(!validateToken) throw new Error('TOKEN INVALIDO');


        const { data } = await clientProducerFarmApi.get<ServiceResponseI<ClientProducerFarmI[]>>(`/all-no-pagination/${producer}`,{ headers: { 'Authorization': validateToken}});
        
        return data;

    }catch(err){
        const obj = (err as AxiosError).response;
        if(obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const getAllClientProducerFarmsbyClient = async (client:number):Promise<ServiceResponseI<ClientProducerFarmI[]>> =>{
    try{ 
        const validateToken = ValidateCurrentToken();
        if(!validateToken) throw new Error('TOKEN INVALIDO');


        const { data } = await clientProducerFarmApi.get<ServiceResponseI<ClientProducerFarmI[]>>(`/all-client-producer-farm-by-client/${client}`,{ headers: { 'Authorization': validateToken}});
        
        return data;

    }catch(err){
        const obj = (err as AxiosError).response;
        if(obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const clientProducerFarmPost = async<T>(getForms: T): Promise<ServiceResponseI<string> > => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await clientProducerFarmApi.post<ServiceResponseI<string>>("", getForms, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const clientProducerFarmPut = async<T>(id: number, updateForms: T): Promise<ServiceResponseI<string>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await clientProducerFarmApi.put<ServiceResponseI<string>>(`/${id}`, updateForms, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const clientProducerFarmById = async (id: number): Promise<ServiceResponseI<ClientProducerFarmI>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await clientProducerFarmApi.get<ServiceResponseI<ClientProducerFarmI>>(`/${id}`, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const clientProducerFarmDelete = async (id: number): Promise<ServiceResponseI<string>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await clientProducerFarmApi.delete<ServiceResponseI<string>>(`/${id}`, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}


export const useClientProducerFarms = ({
    sorting,
    pagination,
    globalFilter,
    client,
    producer,
}:PropsClientProducerFarmI)=>{

    
    const clientProducerFarmsQuery = useQuery(
        ["clientProducerFarms", { pagination, sorting, globalFilter, client, producer, }],
        ()=>getClientProducerFarms({ pagination, sorting, globalFilter, client, producer, }),
        {
            refetchOnWindowFocus:false,
            keepPreviousData:true
        }
    );

    return {
        clientProducerFarmsQuery,
    };
}