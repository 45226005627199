import React from "react";
import {
    Navigate,
} from "react-router-dom";

const ContainerEmptyListComponent = React.lazy(() => import("../../../../../components/container-empty/components/list/page"));
const ContainerEmptyAddComponent = React.lazy(() => import("../../../../../components/container-empty/components/add/page"));
const ContainerEmptyVideosComponent = React.lazy(() => import("../../../../../components/container-empty/components/videos/page"));

export const containerEmptyRoute = [
    {
        path: 'list',
        element: <ContainerEmptyListComponent />,
    },
    {
        path: 'add',
        element: <ContainerEmptyAddComponent />
    },
    {
        path: 'videos',
        element: <ContainerEmptyVideosComponent />
    },
    {
        path: 'edit',
        element: <Navigate to="/container/empty/add" />
    },
    {
        path: 'edit/:id',
        element: <ContainerEmptyAddComponent />
    },
    {
        path: '',
        element: <Navigate replace to="/container/empty/add" />
    },
];