import {
  createBrowserRouter,
  Navigate,
} from "react-router-dom";
import { 
  ErrorPage,
  GenericPage,
  Login, 
  Outdoor,
  Dashboard
} from "../../pages/";
import { Layout } from "../../root";

import { 
  optionsRoute,
  modelsRoute,
  userRoute,
  datasRoute,
  containerRoute,
  truckRoute,
  resourcesRoute,
  extrasRoute,
} from "../routes";
import { lazy } from "react";

const GenericExteriorPage = lazy(() => import("../../pages/lib/exterior-page/page"));
const GenericExteriorPageWfe = lazy(() => import("../../pages/lib/exterior-page/page-wfe"));
const GenericExteriorPageWff = lazy(() => import("../../pages/lib/exterior-page/page-wff"));
const GenericExteriorPageWfcp = lazy(() => import("../../pages/lib/exterior-page/page-wfcp"));

export const router = createBrowserRouter([
  {
    path:"/login",
    element: <Login />,
  },
  {
    path:"/exterior",
    element: <Outdoor />,
    errorElement:<ErrorPage/>, 
    children:[
      {
        path: "container/*",
        element: <GenericExteriorPage />,
        errorElement:<ErrorPage/>,
      },
      {
        path: "workflow-empty/*",
        element: <GenericExteriorPageWfe />,
        errorElement:<ErrorPage/>,
      },
      {
        path: "workflow-full/*",
        element: <GenericExteriorPageWff />,
        errorElement:<ErrorPage/>,
      },
      {
        path: "workflow-checkpoint/*",
        element: <GenericExteriorPageWfcp />,
        errorElement:<ErrorPage/>,
      },
    ]
  },
  {
    path: "/",
    element: <Layout />,
    errorElement:<ErrorPage/>,
    children:[
      {
        path: "dashboard",
        element: <Dashboard />,
        errorElement:<ErrorPage/>,
      },
      {
        path: "container",
        errorElement:<ErrorPage/>,
        children:containerRoute, 
      },
      {
        path: "datas",
        errorElement:<ErrorPage/>,
        children:datasRoute,
      },
      {
        path: "extras",
        errorElement:<ErrorPage/>,
        children:extrasRoute,
      },
      {
        path: "models",
        errorElement:<ErrorPage/>,
        children:modelsRoute, 
      },
      {
        path: "options",
        errorElement:<ErrorPage/>,
        children:optionsRoute, 
      },
      {
        path: "truck",
        element: <GenericPage />,
        errorElement:<ErrorPage/>,
        children:truckRoute, 
      },
      {
        path: "user",
        element: <GenericPage />,
        errorElement:<ErrorPage/>,
        children:userRoute, 
      },
      {
        path: "resources",
        errorElement:<ErrorPage/>,
        children:resourcesRoute, 
      },
      {
        path: '',
        element: <Navigate replace to="dashboard" />
      },
    ],
    
  },
  {
    path:"",
    element: <Login />,
  },
  
  

]);