import { useQuery } from "@tanstack/react-query";
import { profileApi } from "./axios";
import {
    DataI,
    PropsI,
    ServiceResponseI,
    ProfileI,
    PropsProfilesI,
    ProfileTreeI,
} from '../../interfaces';
import {
    ValidateCurrentToken,
    LogoutSystem,
} from '../../helpers'
import { AxiosError } from 'axios';

const getAllProfiles = async ({
    pagination,
    sorting,
    globalFilter,
}: PropsProfilesI): Promise<ServiceResponseI<DataI<ProfileI>>> => {

    try {

        const validateToken = ValidateCurrentToken();

        if (!validateToken) throw new Error('TOKEN INVALIDO');

        const params = new URLSearchParams();

        params.append('page', (pagination!.pageIndex + 1).toString());
        params.append('limit', pagination!.pageSize.toString());
        globalFilter && params.append('search', globalFilter);
        sorting && params.append('sorting', JSON.stringify(sorting));

        //headers: { 'Authorization': validateToken
        const { data } = await profileApi.get<ServiceResponseI<DataI<ProfileI>>>('', { params, headers: { 'Authorization': validateToken } });
        return data;

    } catch (err) {
        const obj = (err as AxiosError).response;

        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }

        
    }


}

export const getAllProfilesNoPagination = async (id:number|null):Promise<ServiceResponseI<ProfileI[]>>=> {

    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');

        if(id){ 
            const { data  } = await profileApi.get<ServiceResponseI<ProfileI[]>>(`/all-no-pagination-by-supplier-id/${id}`, { headers: { 'Authorization': validateToken } });
            return data;
        }

        const { data  } = await profileApi.get<ServiceResponseI<ProfileI[]>>(`/all-no-pagination`, { headers: { 'Authorization': validateToken } });
        return data;


    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}


    export const profilesPost = async<T>(getForms: T): Promise<ServiceResponseI<string>> => {
        try {
            const validateToken = ValidateCurrentToken();
            if (!validateToken) throw new Error('TOKEN INVALIDO');
            const { data } = await profileApi.post("", getForms, { headers: { 'Authorization': validateToken } });
            return data;
        } catch (err) {
            const obj = (err as AxiosError).response;
            if (obj?.status === 401) {
                console.error(obj.statusText);
                LogoutSystem();
            }
            throw new Error((obj?.data as Error).message)
        }
    }

    export const profilesPut = async<T>(id: number, updateForms: T): Promise<ServiceResponseI<string>> => {
        try {
            const validateToken = ValidateCurrentToken();
            if (!validateToken) throw new Error('TOKEN INVALIDO');
            const { data } = await profileApi.put(`/${id}`, updateForms, { headers: { 'Authorization': validateToken } });
            return data;
        } catch (err) {
            const obj = (err as AxiosError).response;
            if (obj?.status === 401) {
                console.error(obj.statusText);
                LogoutSystem();
            }
            throw new Error((obj?.data as Error).message)
        }
    }

    export const profilesDelete = async(id: number): Promise<ServiceResponseI<string>> => {
        try {
            const validateToken = ValidateCurrentToken();
            if (!validateToken) throw new Error('TOKEN INVALIDO');
            const { data } = await profileApi.delete(`/${id}`, { headers: { 'Authorization': validateToken } });
            return data;
        } catch (err) {
            const obj = (err as AxiosError).response;
            if (obj?.status === 401) {
                console.error(obj.statusText);
                LogoutSystem();
            }
            throw new Error((obj?.data as Error).message)
        }
    }

    export const profileById = async (id: number): Promise<ServiceResponseI<ProfileI>> => {
        try {
            const validateToken = ValidateCurrentToken();
            if (!validateToken) throw new Error('TOKEN INVALIDO');
            const { data } = await profileApi.get<ServiceResponseI<ProfileI>>(`/${id}`, { headers: { 'Authorization': validateToken } });
            return data;
        } catch (err) {
            const obj = (err as AxiosError).response;
            if (obj?.status === 401) {
                console.error(obj.statusText);
                LogoutSystem();
            }
            throw new Error((obj?.data as Error).message)
        }
    }

    export const profileByIdTree = async (id: number): Promise<ServiceResponseI<ProfileTreeI>> => {
        try {
            const validateToken = ValidateCurrentToken();
            if (!validateToken) throw new Error('TOKEN INVALIDO');
            const { data } = await profileApi.get<ServiceResponseI<ProfileTreeI>>(`/tree/${id}`, { headers: { 'Authorization': validateToken } });
            return data;
        } catch (err) {
            const obj = (err as AxiosError).response;
            if (obj?.status === 401) {
                console.error(obj.statusText);
                LogoutSystem();
            }
            throw new Error((obj?.data as Error).message)
        }
    }


    export const useProfiles = ({
        sorting,
        pagination,
        globalFilter,
    }: PropsProfilesI) => {

        const profileQuery = useQuery(
            ["profiles", { pagination, sorting, globalFilter }],
            () => getAllProfiles({ pagination, sorting, globalFilter }),
            {
                refetchOnWindowFocus: false,
                keepPreviousData: true
            }
        );

        return {
            profileQuery,
        };
    }