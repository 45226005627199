import React from "react";
import { Outlet } from "react-router-dom";
import {
    Grid,
    Paper,
} from '@mui/material';

import '../css/login.css';

const FondoLogin = require("../images/fondo-min.jpg");


export const Outdoor = () => {
   


    return (
        <Grid
        container
        justifyContent="center"
        alignItems="center"
        className="login-root"
        style={{
            backgroundImage: `url(${FondoLogin})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            overflowX: "hidden", 
            overflowY:"hidden",
            padding:0,
            width:"100vw",
            height:"100vh"
        }}
    >
        <Outlet />
    </Grid>
    );
}