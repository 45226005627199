import React from "react";
import {
    Navigate,
} from "react-router-dom";

const ContainerFullListComponent = React.lazy(() => import("../../../../../components/container-full/components/list/page"));
const ContainerFullAddComponent = React.lazy(() => import("../../../../../components/container-full/components/add/page"));
const ContainerFullVideosComponent = React.lazy(() => import("../../../../../components/container-full/components/videos/page"));


export const containerFullRoute = [
    {
        path: 'list',
        element: <ContainerFullListComponent />,
    },
    {
        path: 'add',
        element: <ContainerFullAddComponent />
    },
    {
        path: 'videos',
        element: <ContainerFullVideosComponent />
    },
    {
        path: 'edit',
        element: <Navigate to="/container/full/add" />
    },
    {
        path: 'edit/:id',
        element: <ContainerFullAddComponent />
    },
    {
        path: '',
        element: <Navigate replace to="/container/full/add" />
    },
];