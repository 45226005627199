import React from "react";
import {
    Navigate,
} from "react-router-dom";

const ExportListComponent = React.lazy(() => import("../../../../../components/exporters/components/list/page"));
const ExportAddComponent = React.lazy(() => import("../../../../../components/exporters/components/add/page"));

export const exportersRoute = [
    {
        path: 'list',
        element: <ExportListComponent />
    },
    {
        path: 'add',
        element: <ExportAddComponent />
    },
    {
        path: '',
        element: <Navigate replace to="/resources/exporters/list" />
    },
];