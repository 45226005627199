import { useQuery } from "@tanstack/react-query";
import { migrationsApi } from "./axios";
import { 
    DataI,
    MigrationsI, 
    PropsI, 
    ServiceResponseI 
} from '../../interfaces';
import {
    ValidateCurrentToken,
    LogoutSystem, 
} from '../../helpers';
import { AxiosError } from 'axios';



const getMigrations = async ({
    pagination, 
    sorting, 
    globalFilter
}:PropsI):Promise<ServiceResponseI<DataI<MigrationsI>>> => {

    try{ 
        
        const validateToken = ValidateCurrentToken();

        if(!validateToken) throw new Error('TOKEN INVALIDO');

        const params = new URLSearchParams();

        params.append('page', (pagination!.pageIndex + 1).toString());
        params.append('limit', pagination!.pageSize.toString());
        params.append('search', globalFilter ?? '');
        params.append('sorting', JSON.stringify(sorting ?? []));
        
        //headers: { 'Authorization': validateToken
        const { data } = await migrationsApi.get<ServiceResponseI<DataI<MigrationsI>>>('',{ params, headers: { 'Authorization': validateToken}});
        return data;
    
    }catch(err){
        const obj = (err as AxiosError).response;

        if(obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
        
    }

    
}


export const migratonsPost = async<T>(getForms: T): Promise<ServiceResponseI<string> > => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await migrationsApi.post<ServiceResponseI<string>>("", getForms, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const migrationsById = async (id:number)=>{
    
}




export const useMigrations = ({
    sorting,
    pagination,
    globalFilter
}:PropsI)=>{

    
    const migrationsQuery = useQuery(
        ["migrations", { pagination, sorting, globalFilter }],
        ()=>getMigrations({ pagination, sorting, globalFilter }),
        {
            refetchOnWindowFocus:false,
            keepPreviousData:true
        }
    );

    return {
        migrationsQuery,
    };
}