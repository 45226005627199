import React from "react";
import {
    Navigate,
} from "react-router-dom";

const NotificationContactListComponent = React.lazy(() => import("../../../../../components/notification-contacts/components/list/page"));
const NotificationContactAddComponent = React.lazy(() => import("../../../../../components/notification-contacts/components/add/page")); 


export const notificationContactsRoute = [
    {
        path: 'list',
        element: <NotificationContactListComponent />,
    },
    {
        path: 'add',
        element: <NotificationContactAddComponent />
    },
    {
        path: 'edit',
        element: <Navigate to="/options/notification-contacts/add" />
    },
    {
        path: 'edit/:id',
        element: <NotificationContactAddComponent />
    },
    {
        path: '',
        element: <Navigate replace to="/options/notification-contacts/list" />
    },
];