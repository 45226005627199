import React from 'react';
import {
  Tooltip,
  IconButton
} from '@mui/material';
import {
  Description as DescriptionIcon
} from '@mui/icons-material';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";


export const ExcelComponent = ({ data, titulo, headersColumn, isEcuafact })=>{

  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (apiData, fileName) => {
    const headersFilter = headersColumn.map(el=>el.id);
    
    apiData = apiData.map(el =>{
      const obj = {};
      headersFilter.forEach(key => {
        obj[key] = el[key];
      });
      return obj;
    });
    console.log(apiData)

    const ws = XLSX.utils.json_to_sheet(apiData);
    console.log(ws)
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };


  return (
    <Tooltip 
      title="Excel"
      placement="top"
      arrow>
      <IconButton 
          color='primary' 
          onClick={(e) => exportToCSV(data, titulo)}
          size='small' 
          style={{ backgroundColor:'#ECF0F1 ', color:'green' }}
          >
          <DescriptionIcon />
      </IconButton>
    </Tooltip>
  );

};