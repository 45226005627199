

import React from "react";
import {
  Navigate,
} from "react-router-dom";


const DatasThermographerList = React.lazy(() => import("../../../../../components/thermographer/components/list/page"));


export const datasThermographerRoutes = [
  {
    path: 'list',
    element: <DatasThermographerList />,
  },
  {
    path: '',
    element: <Navigate replace to="/datas/thermographer/list" />
  },
];