import { useQuery } from "@tanstack/react-query";
import { cantonApi } from "./axios";
import { 
    DataI,
    ServiceResponseI,
    PropsCantonI,
    CantonI
} from '../../interfaces';
import {
    ValidateCurrentToken,
    LogoutSystem, 
} from '../../helpers';
import { AxiosError } from 'axios';


const getCantons = async ({
    pagination, 
    sorting, 
    globalFilter,
    province,
}:PropsCantonI):Promise<ServiceResponseI<DataI<CantonI>>> => {

    try{ 
        
        const validateToken = ValidateCurrentToken();

        if(!validateToken) throw new Error('TOKEN INVALIDO');

        const params = new URLSearchParams();

        params.append('page', (pagination!.pageIndex + 1).toString());
        params.append('limit', pagination!.pageSize.toString());
        params.append('search', globalFilter ?? '');
        params.append('sorting', JSON.stringify(sorting ?? []));
        province && params.append('province', JSON.stringify(province));

        //headers: { 'Authorization': validateToken
        const { data } = await cantonApi.get<ServiceResponseI<DataI<CantonI>>>('',{ params, headers: { 'Authorization': validateToken}});
        return data;
    
    }catch(err){
        const obj = (err as AxiosError).response;

        if(obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }

        
    }

    
}

export const getAllCantonsbyProvince = async (province:number):Promise<ServiceResponseI<CantonI[]>> =>{
    try{ 
        const validateToken = ValidateCurrentToken();
        if(!validateToken) throw new Error('TOKEN INVALIDO');


        const { data } = await cantonApi.get<ServiceResponseI<CantonI[]>>(`/all-no-pagination-by-province/${province}`,{ headers: { 'Authorization': validateToken}});
        
        return data;

    }catch(err){
        const obj = (err as AxiosError).response;
        if(obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        
    }
}

export const cantonPost = async<T>(getForms: T): Promise<ServiceResponseI<string>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await cantonApi.post<ServiceResponseI<string>>("", getForms, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const cantonPut = async<T>(id: number, updateForms: T): Promise<ServiceResponseI<string>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await cantonApi.put<ServiceResponseI<string>>(`/${id}`, updateForms, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const cantonById = async (id: number): Promise<ServiceResponseI<CantonI>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await cantonApi.get<ServiceResponseI<CantonI>>(`/${id}`, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const cantonDelete = async (id: number): Promise<ServiceResponseI<string>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await cantonApi.delete<ServiceResponseI<string>>(`/${id}`, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}


export const useCantons = ({
    sorting,
    pagination,
    globalFilter,
    province
}:PropsCantonI)=>{

    
    const cantonsQuery = useQuery(
        ["cantons", { pagination, sorting, globalFilter, province }],
        ()=>getCantons({ pagination, sorting, globalFilter, province }),
        {
            refetchOnWindowFocus:false,
            keepPreviousData:true
        }
    );

    return {
        cantonsQuery,
    };
}