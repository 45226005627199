import React, {
  Fragment,
  useEffect,
  useState,
  useRef,
  useCallback,
  useContext,
} from "react";
import Webcam from "react-webcam";
import { Chip, Dialog, DialogContent } from "@mui/material";
import {
  Button as ButtonAntd,
  ConfigProvider,
  message,
  Popconfirm,
} from "antd";
import {
  PlayCircleFilled,
  PauseCircleFilled,
  SaveFilled,
  InteractionTwoTone,
  BulbTwoTone,
  CloseCircleOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { css } from "@emotion/css";

interface VideoComponentPropsI {
  open: boolean;
  handleClose: () => void;
  setVideo: (video: string) => void;
  elvideo: string;
  time: number;
  timeString: string;
  title: string;
  audioBit?: number;
  videoBit?: number;
  dbName: string;
  storeName: string;
}

const CHUNK_SIZE = 1024 * 1024; // Tamaño de cada chunk en bytes

// Inicializar IndexedDB
const initIndexedDB = (dbName: string, storeName: string) => {
  return new Promise<IDBDatabase>((resolve, reject) => {
    let version = 1;
    const existingRequest = indexedDB.open(dbName);

    existingRequest.onsuccess = () => {
      const db = existingRequest.result;
      version = db.version + 1; // Incrementar la versión
      db.close();

      const request = indexedDB.open(dbName, version);

      request.onupgradeneeded = (event) => {
        const db = (event.target as IDBOpenDBRequest).result;
        if (!db.objectStoreNames.contains(storeName)) {
          db.createObjectStore(storeName, {
            keyPath: "id",
            autoIncrement: true,
          });
        }
      };

      request.onsuccess = (event) => {
        resolve((event.target as IDBOpenDBRequest).result);
      };

      request.onerror = (event) => {
        reject((event.target as IDBOpenDBRequest).error);
      };
    };

    existingRequest.onerror = (event) => {
      reject((event.target as IDBOpenDBRequest).error);
    };
  });
};

// Guardar chunk en IndexedDB
const saveChunkToIndexedDB = async (
  db: IDBDatabase,
  storeName: string,
  chunk: Blob
) => {
  return new Promise<void>((resolve, reject) => {
    const transaction = db.transaction([storeName], "readwrite");
    const store = transaction.objectStore(storeName);
    const request = store.add({ chunk });

    request.onsuccess = () => resolve();
    request.onerror = (event) => reject((event.target as IDBRequest).error);
  });
};

// Obtener todos los chunks y ensamblar el Blob
const getVideoBlobFromIndexedDB = async (
  db: IDBDatabase,
  storeName: string
): Promise<Blob> => {
  return new Promise<Blob>((resolve, reject) => {
    const transaction = db.transaction([storeName], "readonly");
    const store = transaction.objectStore(storeName);
    const request = store.getAll();

    request.onsuccess = () => {
      const allChunks = request.result.map((entry: any) => entry.chunk);
      const blob = new Blob(allChunks, { type: "video/mp4" });
      resolve(blob);
    };

    request.onerror = (event) => {
      reject((event.target as IDBRequest).error);
    };
  });
};

const clearIndexedDB = async (db: IDBDatabase, storeName: string) => {
  return new Promise<void>((resolve, reject) => {
    const transaction = db.transaction([storeName], "readwrite");
    const store = transaction.objectStore(storeName);
    const request = store.clear();

    request.onsuccess = () => {
      console.log("IndexedDB cleared successfully");
      resolve();
    };

    request.onerror = (event) => {
      console.error(
        "Error clearing IndexedDB:",
        (event.target as IDBRequest).error
      );
      reject((event.target as IDBRequest).error);
    };
  });
};

export const VideoComponent: React.FC<VideoComponentPropsI> = ({
  open,
  handleClose,
  setVideo,
  elvideo,
  time,
  timeString,
  title,
  audioBit,
  videoBit,
  dbName,
  storeName,
}) => {
  const openId = useRef<number>(1);
  const webcamRef = useRef<Webcam | any>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const [recording, setRecording] = useState(false);
  const [paused, setPaused] = useState(false);
  const [db, setDb] = useState<IDBDatabase | null>(null);
  const [seconds, setSeconds] = useState(0); // Contador de segundos
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const [processingVideo, setProcessingVideo] = useState(false);
  const { getPrefixCls } = useContext(ConfigProvider.ConfigContext);
  const rootPrefixCls = getPrefixCls();
  const [activeDeviceId, setActiveDeviceId] = useState<string | undefined>(
    undefined
  );
  const [videoConstraints, setVideoConstraints] = useState<any>({
    deviceId: activeDeviceId,
    facingMode: "environment",
    autoFocus: "continuous",
    whiteBalance: "continuous",
    frameRate: 25,
    zoom: 0,
    focusDepth: 0,
    width: { ideal: 1280 },
    height: { ideal: 720 },
    torch: false,
  } as unknown as any);
  const [continuoChange, setContinuoChange] = useState<boolean>(false);
  const [numberOfCameras, setNumberOfCameras] = useState(0);
  const [mirror, setMirror] = useState(false);
  const [isChangingCamera, setChangingCamera] = useState<boolean>(false);
  const [hasData, setHasData] = useState(false);

  useEffect(() => {
    let dbConnection: IDBDatabase | null = null;
    handleGetDevices();

    initIndexedDB(dbName, storeName)
      .then((database) => {
        dbConnection = database;
        setDb(database);
        checkForStoredData(database);
      })
      .catch((error) => console.error("Error initializing IndexedDB", error));

    // Limpiar conexión al desmontar
    return () => {
      webcamRef.current! = null;
      setNumberOfCameras(0);
      setActiveDeviceId(undefined);
      setSeconds(0);
      if (dbConnection) {
        dbConnection.close();
        handleClearRemove();
      }
    };
  }, [dbName, storeName]);

  useEffect(() => {
    if (!open) {
      // Increment id each time modal closes
      openId.current = openId.current + 1;
    }
  }, [open]);

  useEffect(() => {
    if (continuoChange) {
      setContinuoChange(false);
      setChangingCamera(false);
    }
  }, [continuoChange]);

  const handleClearRemove = async () => {
    try {
      setHasData(false); // Actualizar estado
      setSeconds(0); // Reiniciar contador
      await closeAndClearIndexedDB(dbName, storeName);
    } catch (error: any) {
      if (error.message.includes("blocked")) {
        console.warn("Retrying database deletion...");
        handleClearRemove();
      } else {
        console.error("Error clearing the database:", error);
        message.error("Failed to clear database.");
      }
    }
  };

  const checkForStoredData = useCallback(
    (database: IDBDatabase) => {
      const transaction = database.transaction([storeName], "readonly");
      const store = transaction.objectStore(storeName);
      const request = store.count();

      request.onsuccess = () => {
        setHasData(request.result > 0);
      };

      request.onerror = () => {
        console.error("Error checking for stored data");
        setHasData(false);
      };
    },
    [storeName]
  );

  const handleGetDevices = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter((i) => i.kind == "videoinput");
      setNumberOfCameras(videoDevices.length);
    } catch (err) {
      console.log(err);
    }
  };

  const startRecording = useCallback(() => {
    if (!db || !webcamRef.current?.stream) {
      console.error("IndexedDB or Webcam is not initialized");
      return;
    }

    setRecording(true);
    setPaused(false);
    setSeconds(0);
    const options = {
      mimeType: "video/mp4", // Código de video
      audioBitsPerSecond: audioBit, // Bits por segundo
      videoBitsPerSecond: videoBit, // Bits por segundo
    };
    const mediaRecorder = new MediaRecorder(webcamRef.current.stream, options);

    mediaRecorder.ondataavailable = async (event) => {
      if (event.data.size > 0) {
        const chunk = event.data;
        await saveChunkToIndexedDB(db, storeName, chunk);
        checkForStoredData(db);
      }
    };

    mediaRecorder.onstop = () => {
      setRecording(false);
      setPaused(false);
      clearInterval(timerRef.current!);
      timerRef.current = null;
      console.log("Recording stopped");
    };

    mediaRecorder.start(); // Captura cada segundo
    mediaRecorderRef.current = mediaRecorder;

    timerRef.current = setInterval(() => {
      setSeconds((prev) => prev + 1);
    }, 1000);
  }, [db, storeName, checkForStoredData]);

  const pauseRecording = useCallback(() => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state === "recording"
    ) {
      mediaRecorderRef.current.pause();
      setPaused(true);
      clearInterval(timerRef.current!);
      timerRef.current = null;
    }
  }, []);

  const resumeRecording = useCallback(() => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state === "paused"
    ) {
      mediaRecorderRef.current.resume();
      setPaused(false);
      timerRef.current = setInterval(() => {
        setSeconds((prev) => prev + 1);
      }, 1000);
    }
  }, []);

  const stopRecording = useCallback(() => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
    }
  }, []);

  const handleSaveVideo = useCallback(async () => {
    if (!db) return;

    try {
      setProcessingVideo(true);

      const blob = await getVideoBlobFromIndexedDB(db, storeName);
      setVideo(blob as unknown as string);
      setProcessingVideo(false);
      setHasData(false);
      handleClose();
    } catch (error) {
      console.error("Error retrieving video blob:", error);
    }
  }, [db, setVideo, storeName]);

  const linearGradientButton = css`
    &.${rootPrefixCls}-btn-primary:not([disabled]):not(
        .${rootPrefixCls}-btn-dangerous
      ) {
      border-width: 0;

      > span {
        position: relative;
      }

      &::before {
        content: "";
        background: linear-gradient(135deg, #08b806, #7fffd4);
        position: absolute;
        inset: 0;
        opacity: 1;
        transition: all 0.3s;
        border-radius: inherit;
      }

      &:hover::before {
        opacity: 0.7;
      }
    }
  `;

  const linearGradientButtonClear = css`
    &.${rootPrefixCls}-btn-primary:not([disabled]):not(
        .${rootPrefixCls}-btn-dangerous
      ) {
      border-width: 0;

      > span {
        position: relative;
      }

      &::before {
        content: "";
        background: linear-gradient(135deg, #b22222, #f08080);
        position: absolute;
        inset: 0;
        opacity: 1;
        transition: all 0.3s;
        border-radius: inherit;
      }

      &:hover::before {
        opacity: 0.8;
      }
    }
  `;

  const handleTorch = () => {
    if (videoConstraints.facingMode === "user") return;
    message.info(videoConstraints.torch ? "Turn Off Flash" : "Trun On Flash");
    setChangingCamera(true);
    setVideoConstraints((constraints: any) => ({
      ...constraints,
      torch: !constraints.torch,
    }));
    setContinuoChange(true);
  };

  const changeFacingMode = () => {
    message.info("Cambiando el modo de cámara");
    setChangingCamera(true);
    if (videoConstraints.facingMode === "environment") {
      setMirror(true);
    } else {
      setMirror(false);
    }
    setVideoConstraints((constraints: any) => ({
      ...constraints,
      facingMode: constraints.facingMode === "user" ? "environment" : "user",
    }));
    setContinuoChange(true);
  };

  const handleClearRecording = useCallback(async () => {
    if (!db) return;

    try {
      await clearIndexedDB(db, storeName);
      setHasData(false); // Actualizar el estado para deshabilitar el botón de guardar
      setSeconds(0); // Reiniciar el contador de tiempo
      message.success("Recording data cleared successfully!");
    } catch (error) {
      console.error("Error clearing recording data:", error);
      message.error("Failed to clear recording data");
    }
  }, []);

  const handleClearTotal = async () => {
    try {
      setVideo("");
      setHasData(false); // Actualizar estado
      setSeconds(0); // Reiniciar contador
      await closeAndClearIndexedDB(dbName, storeName);
      handleClose();
      message.success("Video cleared successfully!");
    } catch (error: any) {
      if (error.message.includes("blocked")) {
        console.warn("Retrying database deletion...");
        handleClose();
      } else {
        console.error("Error clearing the database:", error);
        message.error("Failed to clear database.");
      }
    }
  };

  return (
    <Fragment>
      <Dialog
        keepMounted={false}
        key={openId.current}
        fullScreen
        open={open}
        onClose={handleClose}
        // TransitionComponent={Transition}
        sx={{
          overflowY: "hidden",
          overflowX: "hidden",
          p: 0,
          m: 0,
        }}
      >
        <DialogContent
          sx={{
            overflowY: "hidden",
            overflowX: "hidden",
            p: 0,
            m: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          {elvideo ? null : (
            <ButtonAntd
              style={{
                position: "absolute",
                bottom: 15,
                left: "calc(50vw - 87px)",
                zIndex: 2,
              }}
              size="large"
              disabled={numberOfCameras < 2 || recording}
              onClick={changeFacingMode}
              type="dashed"
              shape="circle"
              icon={
                <InteractionTwoTone
                  twoToneColor={numberOfCameras < 2 ? "#FFD700" : "#FF0000"}
                />
              }
            />
          )}
          {elvideo ? null : (
            <ButtonAntd
              style={{
                position: "absolute",
                top: 1,
                left: 1,
                zIndex: 2,
              }}
              size="large"
              onClick={handleTorch}
              type="dashed"
              shape="circle"
              disabled={recording}
              icon={
                <BulbTwoTone
                  twoToneColor={videoConstraints.torch ? "#FFD700" : "#FF0000"}
                />
              }
            />
          )}
          {elvideo ? (
            <Fragment>
              <ButtonAntd
                style={{
                  position: "absolute",
                  top: 5,
                  right: 5,
                  zIndex: 1601,
                }}
                size="large"
                onClick={handleClose}
                type="primary"
                danger
                icon={<CloseCircleOutlined />}
              />
              <ButtonAntd
                style={{
                  position: "absolute",
                  top: 5,
                  right: 55,
                  zIndex: 1601,
                }}
                size="large"
                onClick={handleClearTotal}
                type="primary"
                shape="circle"
                icon={<ReloadOutlined />}
              />
              <VideoPlayer elvideo={elvideo} setVideo={setVideo} />
            </Fragment>
          ) : (
            <Fragment>
              <ButtonAntd
                style={{
                  position: "absolute",
                  top: 5,
                  right: 5,
                  zIndex: 1601,
                }}
                size="large"
                onClick={handleClose}
                type="primary"
                danger
                icon={<CloseCircleOutlined />}
              />

              {isChangingCamera ? null : (
                <Webcam
                  height="100%"
                  audio={true}
                  mirrored={mirror}
                  ref={webcamRef}
                  // screenshotQuality={0.8}
                  videoConstraints={videoConstraints}
                  style={{ minHeight: "100%" }}
                  muted={true}
                  // screenshotFormat="image/webp"
                />
              )}
              {recording ? (
                <Fragment>
                  <Chip
                    color="error"
                    label={seconds}
                    size="medium"
                    sx={{
                      position: "absolute",
                      top: 5,
                      left: 50,
                      zIndex: 1601,
                      width: 50,
                    }}
                  />
                  <ButtonAntd
                    style={{
                      position: "absolute",
                      bottom: 10,
                      left: "calc(50vw - 110px)",
                      width: 70,
                      height: 70,
                      zIndex: 100,
                    }}
                    size="large"
                    onClick={paused ? resumeRecording : pauseRecording}
                    type="dashed"
                    shape="circle"
                    icon={paused ? <PlayCircleFilled /> : <PauseCircleFilled />}
                  />
                  <ButtonAntd
                    style={{
                      position: "absolute",
                      bottom: 10,
                      left: "calc(50vw - 35px)",
                      width: 70,
                      height: 70,
                    }}
                    size="large"
                    onClick={stopRecording}
                    type="primary"
                    danger
                    shape="circle"
                    icon={<p>⬜️</p>}
                  />
                </Fragment>
              ) : (
                <ButtonAntd
                  style={{
                    position: "absolute",
                    bottom: 10,
                    left: "calc(50vw - 35px)",
                    width: 70,
                    height: 70,
                  }}
                  size="large"
                  onClick={startRecording}
                  type="primary"
                  shape="circle"
                  icon={<p>🔴</p>}
                />
              )}
              {hasData && !recording ? (
                <Fragment>
                  <ConfigProvider
                    button={{
                      className: linearGradientButtonClear,
                    }}
                  >
                    <Popconfirm
                      title="Limpiar"
                      description="Se borrará todo el video"
                      onConfirm={handleClearRecording}
                      okText="Si"
                      cancelText="No"
                      zIndex={1601}
                    >
                      <ButtonAntd
                        style={{
                          position: "absolute",
                          bottom: 20,
                          left: "calc(50vw + 110px)",
                          width: 50,
                          height: 50,
                          zIndex: 1601,
                        }}
                        size="large"
                        type="primary"
                        icon={<ReloadOutlined size={40} />}
                      />
                    </Popconfirm>
                  </ConfigProvider>
                  <ConfigProvider
                    button={{
                      className: linearGradientButton,
                    }}
                  >
                    <ButtonAntd
                      style={{
                        position: "absolute",
                        bottom: 20,
                        left: "calc(50vw + 50px)",
                        width: 50,
                        height: 50,
                        zIndex: 1601,
                      }}
                      size="large"
                      onClick={handleSaveVideo}
                      type="primary"
                      loading={processingVideo}
                      icon={<SaveFilled size={40} />}
                    />
                  </ConfigProvider>
                </Fragment>
              ) : null}
            </Fragment>
          )}
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

interface VideoPlayer {
  elvideo: string;
  // handleClose: () => void;
  setVideo: (video: any) => void;
  // handleClearRecording: () => void;
}

const VideoPlayer = ({
  elvideo,
  // handleClose,
  setVideo,
}: // handleClearRecording,
VideoPlayer) => {
  return (
    <video controls style={{ width: "100%", height: "100vh" }}>
      <source type="video/mp4" src={elvideo} />
    </video>
  );
};

const closeAndClearIndexedDB = async (dbName: string, storeName: string) => {
  return new Promise<void>((resolve, reject) => {
    // Cerrar conexiones abiertas antes de eliminar
    const request = indexedDB.open(dbName);
    request.onsuccess = (event) => {
      const db = (event.target as IDBOpenDBRequest).result;
      db.close(); // Cierra la conexión
      const deleteRequest = indexedDB.deleteDatabase(dbName); // Elimina la base de datos

      deleteRequest.onsuccess = () => {
        console.log(`Database "${dbName}" deleted successfully.`);
        resolve();
      };

      deleteRequest.onerror = (event) => {
        console.error(
          `Error deleting database "${dbName}":`,
          (event.target as IDBRequest).error
        );
        reject((event.target as IDBRequest).error);
      };

      deleteRequest.onblocked = () => {
        console.error(`Database "${dbName}" deletion is blocked.`);
        reject(new Error("Deletion blocked by another connection."));
      };
    };

    request.onerror = (event) => {
      console.error(
        `Error opening database "${dbName}":`,
        (event.target as IDBOpenDBRequest).error
      );
      reject((event.target as IDBOpenDBRequest).error);
    };
  });
};
