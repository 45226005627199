import React from "react";
import {
    Navigate,
} from "react-router-dom";

const ProvinceListComponent = React.lazy(() => import("../../../../../components/province/components/list/page"));
const ProvinceAddComponent = React.lazy(() => import("../../../../../components/province/components/add/page"));

export const provinceRoute = [
    {
        path: 'list',
        element: <ProvinceListComponent />
    },
    {
        path: 'add',
        element: <ProvinceAddComponent />
    },
    {
        path: 'edit',
        element: <Navigate to="/models/provinces/add" />
    },
    {
        path: 'edit/:id',
        element: <ProvinceAddComponent />
    },
    {
        path: '',
        element: <Navigate replace to="/models/provinces/list" />
    },
];