import React, {useEffect, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import "../css/upload.css"

const thumbsContainer:React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16
};

const thumb:React.CSSProperties = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box'
};

const thumbInner:React.CSSProperties = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
};

const img:React.CSSProperties = {
  display: 'block',
  width: 'auto',
  height: '100%'
};

export interface FileI{
  preview: Blob;
  name: string;
  Blob: any;
}

type typeFile = 'image/*' | 'application/pdf' | 'video/*';

interface PropsI{
    handleFile:(acceptedFiles:any)=>void;
    files:FileI[];
    maxFiles:number;
    typeFile:typeFile,
    nameElement?:string;
}

// const [files, setFiles] = useState<FileI[]>([]);

// const handleFile = useCallback((acceptedFiles: any) => {
//   setFiles(acceptedFiles.map((file: any) => Object.assign(file, {
//     preview: URL.createObjectURL(file)
//   })));
// }, [files]);

export function Previews({
    handleFile,
    files,
    maxFiles=1,
    typeFile='image/*',
    nameElement="Avatar"
}:PropsI) {
  const {getRootProps, getInputProps} = useDropzone({
    maxFiles,
    accept: {
        [typeFile]: []
    },
    onDrop: acceptedFiles => {
        handleFile(acceptedFiles)
    }
  });
  

  const thumbs = files.map(file => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          src={file.preview as unknown as string}
          style={img}
          // Revoke data uri after image is loaded
          onLoad={() => { URL.revokeObjectURL(file.preview as unknown as string) }}
        />
      </div>
    </div>
  ));

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach(file => URL.revokeObjectURL(file.preview as unknown as string));
  }, []);

  return (
    <section className="container">
      <div {...getRootProps({className: 'dropzone'})}>
        <input {...getInputProps()} />
        <p style={{textAlign:"center"}}>Drag 'n' drop some img here, or click to select files<br/> Only {maxFiles} Image for {nameElement}</p>
      </div>
      <aside style={thumbsContainer}>
        {thumbs}
      </aside>
    </section>
  );
}