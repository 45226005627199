import React from "react";
import {
    Navigate,
} from "react-router-dom";

const ContainerCheckPointListComponent = React.lazy(() => import("../../../../../components/checkpoint/components/list/page"));
const ContainerCheckPointAddComponent = React.lazy(() => import("../../../../../components/checkpoint/components/add/page"));


export const containerCheckPointRoute = [
    {
        path: 'list',
        element: <ContainerCheckPointListComponent />,
    },
    {
        path: 'add',
        element: <ContainerCheckPointAddComponent />
    },
    {
        path: 'edit',
        element: <Navigate to="/container/checkpoint/add" />
    },
    {
        path: 'edit/:id',
        element: <ContainerCheckPointAddComponent />
    },
    {
        path: '',
        element: <Navigate replace to="/container/checkpoint/add" />
    },
];