import React from "react";
import {
    Navigate,
} from "react-router-dom";

const ContainerErrorListComponent = React.lazy(() => import("../../../../../components/container-error/page"));


export const containerErrorPointRoute = [
    {
        path: 'list',
        element: <ContainerErrorListComponent />,
    },
    {
        path: '',
        element: <Navigate replace to="/container/error/list" />
    },
];