import { useQuery } from "@tanstack/react-query";
import { clientBrandApi } from "./axios";
import { 
    DataI,
    ServiceResponseI,
    PropsClientBrandI,
    ClientBrandI
} from '../../interfaces';
import {
    ValidateCurrentToken,
    LogoutSystem, 
} from '../../helpers';
import { AxiosError } from 'axios';


const getClientBrands = async ({
    pagination, 
    sorting, 
    globalFilter,
    client,
}:PropsClientBrandI):Promise<ServiceResponseI<DataI<ClientBrandI>>>  => {

    try{ 
        
        const validateToken = ValidateCurrentToken();

        if(!validateToken) throw new Error('TOKEN INVALIDO');

        const params = new URLSearchParams();

        params.append('page', (pagination!.pageIndex + 1).toString());
        params.append('limit', pagination!.pageSize.toString());
        params.append('search', globalFilter ?? '');
        params.append('sorting', JSON.stringify(sorting ?? []));
        client && params.append('client', client.toString());

        //headers: { 'Authorization': validateToken
        const { data } = await clientBrandApi.get<ServiceResponseI<DataI<ClientBrandI>>>('',{ params, headers: { 'Authorization': validateToken}});
        return data;
    
    }catch(err){
        console.log(err);
        const obj = (err as AxiosError).response;

        if(obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }

        throw new Error((obj?.data as Error).message)
    }

    
}

export const getAllClientBrands = async (client:number):Promise<ServiceResponseI<ClientBrandI[]>> =>{
    try{ 
        const validateToken = ValidateCurrentToken();
        if(!validateToken) throw new Error('TOKEN INVALIDO');


        const { data } = await clientBrandApi.get<ServiceResponseI<ClientBrandI[]>>(`/all-no-pagination/${client}`,{ headers: { 'Authorization': validateToken}});
        
        return data;

    }catch(err){
        const obj = (err as AxiosError).response;
        if(obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const clientBrandPost = async<T>(getForms: T): Promise<ServiceResponseI<string> > => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await clientBrandApi.post<ServiceResponseI<string>>("", getForms, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const clientBrandPut = async<T>(id: number, updateForms: T): Promise<ServiceResponseI<string>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await clientBrandApi.put<ServiceResponseI<string>>(`/${id}`, updateForms, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const clientBrandById = async (id: number): Promise<ServiceResponseI<ClientBrandI>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await clientBrandApi.get<ServiceResponseI<ClientBrandI>>(`/${id}`, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const clientBrandDelete = async (id: number): Promise<ServiceResponseI<string>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await clientBrandApi.delete<ServiceResponseI<string>>(`/${id}`, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}


export const useClientBrands = ({
    sorting,
    pagination,
    globalFilter,
    client,
}:PropsClientBrandI)=>{

    
    const clientBrandsQuery = useQuery(
        ["clientBrands", { pagination, sorting, globalFilter, client }],
        ()=>getClientBrands({ pagination, sorting, globalFilter, client }),
        {
            refetchOnWindowFocus:false,
            keepPreviousData:true
        }
    );

    return {
        clientBrandsQuery,
    };
}