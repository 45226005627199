import { memo } from "react";
import { CameraOutlined } from "@ant-design/icons";
import { Grid } from "@mui/material";
import { Button as ButtonAntd, Image, Space } from "antd";
import { CameraComponentExporBananas } from "./camera-component";
import noImg from "../../../../images/no-image.png";
// import { useWidth } from "../../../hooks";

interface OnlyPhotoPropsI {
  handleClose: () => void;
  handleOpen: () => void;
  open: boolean;
  foto: string;
  setFoto: (foto: string) => void;
  label: string;
  width?: number;
  fontSize?: number;
}

export const OnlyPhoto = memo(
  ({
    handleClose,
    handleOpen,
    open,
    foto,
    setFoto,
    label,
    width = 220,
    fontSize = 10,
  }: OnlyPhotoPropsI) => {
    // const widthFull = useWidth();

    return (
      <Grid
        container
        spacing={0.5}
        justifyContent={"center"}
        alignContent="center"
        alignItems="center"
      >
        <Grid item>
          <Space.Compact>
            <ButtonAntd
              type="primary"
              size="large"
              style={{
                width,
                fontSize,
                fontWeight:"bold",
                textWrap: "wrap",
                textAlign: "center",
                lineHeight: 1.2,
              }}
              onClick={handleOpen}
            >
              {label}
            </ButtonAntd>
            <ButtonAntd
              onClick={handleOpen}
              type="dashed"
              size="large"
              icon={<CameraOutlined />}
            />
          </Space.Compact>
        </Grid>
        <Grid item sx={{ display: "flex", justifyContent: "center" }}>
          <Image
            src={foto === "" ? noImg : foto}
            style={{
              width: 36,
              height: 36,
              borderRadius: 5,
              boxShadow: "0px 10px 15px 0px rgba(0,0,0,0.1)",
            }}
          />
          <CameraComponentExporBananas
            open={open}
            handleClose={handleClose}
            setFoto={setFoto}
          />
        </Grid>
      </Grid>
    );
  });
