import React from "react";
import {
    Navigate,
} from "react-router-dom";

const ClientListComponent = React.lazy(() => import("../../../../../components/clients/components/list/page"));
const ClientAddComponent = React.lazy(() => import("../../../../../components/clients/components/add/create"));

export const clientRoute = [
    {
        path: 'list',
        element: <ClientListComponent />
    },
    {
        path: 'add',
        element: <ClientAddComponent />
    },
    {
        path: 'edit',
        element: <Navigate to="/resources/clients/add" />
    },
    {
        path: 'edit/:id',
        element: <ClientAddComponent />
    },
    {
        path: '',
        element: <Navigate replace to="/resources/clients/list" />
    },
];