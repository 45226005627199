import React from "react";
import { GenericPage } from "../../../../../pages";
import {
  containerEmptyRoute
} from "./container-empty.route";
import {
  containerFullRoute
} from "./container-full.route";
import {
  containerCheckPointRoute
} from "./container-checkpoint.route";
import { containerErrorPointRoute } from "./container-error.route";



export const containerRoute = [
  {
    path: 'empty',
    element: <GenericPage />,
    children:containerEmptyRoute,
  },
  {
    path: 'full',
    element: <GenericPage />,
    children:containerFullRoute,
  },
  {
    path: 'checkpoint',
    element: <GenericPage />,
    children:containerCheckPointRoute,
  },
  {
    path: 'checkpoint',
    element: <GenericPage />,
    children:containerCheckPointRoute,
  },
  {
    path: 'error',
    element: <GenericPage />,
    children:containerErrorPointRoute,
  },
  
]