export enum Environments {
    CODE_PARAMETER_MAIL = 'MAIL',
    CODE_PARAMETER_HOST_EMAIL = 'HOST_MAIL',
    CODE_PARAMETER_PORT_EMAIL = 'PORT_MAIL',
    CODE_PARAMETER_USER_EMAIL = 'USER_MAIL',
    CODE_PARAMETER_PASS_EMAIL = 'PASSWORD_MAIL',
    CODE_PARAMETER_EMISOR_EMAIL = 'EMISOR_MAIL',
    PARAMETER_EMAIL_LOGO = 'MAIL_LOGO',
    LIMIT = 15,
    PAGE = 1,
    TIMEZONE_HOURS = 5,
    TIMEZONE_HOURS_MONGO = 0,
    TASK_TRUCK_ASSIGN="TASK_TRUCK_ASIGNAR_0001",
    TASK_CONTAINER_FULL_ASSIGN="TASK_FULL_ASIGNAR_0001",
    TASK_CONTAINER_EMPTY_ASSIGN="TASK_VACIO_ASIGNAR_0001",
    TASK_CONTAINER_CHECKPOINT_ASSIGN="TASK_CHECKPOINT_ASIGNAR_0001",
    ASSIGN_USER_TO_CLIENT_CODE="TASK_USUARIOS_ASIGNAR_0001",
  }
  