import ImageJs from "image-js";

export const handleWatermark = (photo: any, producer: string, farm: string) =>
  new Promise(async (resolve, reject) => {
    // const photo = camera.current.getScreenshot();
    const image = await ImageJs.load(photo);
    const canvas = image.getCanvas();
    const ctx = canvas.getContext("2d");
    // Define the rectangle properties
    const rectWidth = image.width;
    const rectHeight = 40;
    const rectX = 0; // Adjust position as needed
    const rectY = image.height - rectHeight; // Adjust position as needed
    const borderRadius = 0; // Border radius for rounded corners

    // Draw the gradient rectangle with rounded corners
    const gradient = ctx!.createLinearGradient(
      rectX,
      rectY,
      rectX,
      rectY + rectHeight
    );
    gradient.addColorStop(0, "rgba(0, 0, 0, 0.70)"); // Start with black and 70% opacity
    gradient.addColorStop(0.5, "rgba(3, 34, 99, 0.70)"); // Transition to #032263 with 70% opacity
    gradient.addColorStop(0.8, "rgba(3, 34, 99, 0.70)"); // Transition to #032263 with 70% opacity
    gradient.addColorStop(1, "rgba(160, 191, 242, 0.70)"); // End with a light blue with 70% opacity
    ctx!.fillStyle = gradient;
    ctx!.beginPath();
    ctx!.moveTo(rectX + borderRadius, rectY);
    ctx!.lineTo(rectX + rectWidth - borderRadius, rectY);
    ctx!.quadraticCurveTo(
      rectX + rectWidth,
      rectY,
      rectX + rectWidth,
      rectY + borderRadius
    );
    ctx!.lineTo(rectX + rectWidth, rectY + rectHeight - borderRadius);
    ctx!.quadraticCurveTo(
      rectX + rectWidth,
      rectY + rectHeight,
      rectX + rectWidth - borderRadius,
      rectY + rectHeight
    );
    ctx!.lineTo(rectX + borderRadius, rectY + rectHeight);
    ctx!.quadraticCurveTo(
      rectX,
      rectY + rectHeight,
      rectX,
      rectY + rectHeight - borderRadius
    );
    ctx!.lineTo(rectX, rectY + borderRadius);
    ctx!.quadraticCurveTo(rectX, rectY, rectX + borderRadius, rectY);
    ctx!.closePath();
    ctx!.fill();

    // Define the text properties
    const date = new Date();
    const timeString = date.toLocaleTimeString(undefined, {
      hour12: false,
      timeStyle: "short",
    });
    const dateString = date.toLocaleDateString();

    // Custom info
    // const clientName = client;
    const producerName = producer;
    const farmName = farm;

    ctx!.fillStyle = "yellowgreen";
    ctx!.font = "25px Arial";
    // ctx!.fontStretch= "semi-condensed"
    ctx!.textAlign = "left";
    ctx!.textBaseline = "top";

    const wrapText = (
      text: string,
      x: number,
      y: number,
      maxWidth: number,
      lineHeight: number
    ) => {
      const words = text.split(" ");
      let line = "";
      let lineCount = 0;

      for (let i = 0; i < words.length; i++) {
        const testLine = line + words[i] + " ";
        const metrics = ctx!.measureText(testLine);
        const testWidth = metrics.width;

        if (testWidth > maxWidth && i > 0) {
          ctx!.fillText(line, x, y + lineCount * lineHeight);
          line = words[i] + " ";
          lineCount++;
        } else {
          line = testLine;
        }
      }
      ctx!.fillText(line, x, y + lineCount * lineHeight);
      return y + (lineCount + 1) * lineHeight; // Return the next starting Y position
    };

    // Add the text with wrapping
    ctx!.font = "bold 22px Arial";
    let startY = rectY + 6;
    startY = wrapText(
      `Productor: ${producerName}, Finca: ${farmName}, ${dateString} ${timeString}`,
      rectX + 10,
      startY,
      rectWidth - 5,
      20
    );

    const base64Image = canvas.toDataURL("image/webp");
    resolve(base64Image);
  });

export const handleWatermarkCheckPoint = (photo: any) =>
  new Promise(async (resolve, reject) => {
    const image = await ImageJs.load(photo);
    const canvas = image.getCanvas();
    const ctx = canvas.getContext("2d");
    // Define the rectangle properties
    const rectWidth = image.width;
    const rectHeight = 40;
    const rectX = 0; // Adjust position as needed
    const rectY = image.height - rectHeight; // Adjust position as needed
    const borderRadius = 0; // Border radius for rounded corners

    // Draw the gradient rectangle with rounded corners
    const gradient = ctx!.createLinearGradient(
      rectX,
      rectY,
      rectX,
      rectY + rectHeight
    );
    gradient.addColorStop(0, "rgba(0, 0, 0, 0.70)"); // Start with black and 70% opacity
    gradient.addColorStop(0.5, "rgba(3, 34, 99, 0.70)"); // Transition to #032263 with 70% opacity
    gradient.addColorStop(0.8, "rgba(3, 34, 99, 0.70)"); // Transition to #032263 with 70% opacity
    gradient.addColorStop(1, "rgba(160, 191, 242, 0.70)"); // End with a light blue with 70% opacity
    ctx!.fillStyle = gradient;
    ctx!.beginPath();
    ctx!.moveTo(rectX + borderRadius, rectY);
    ctx!.lineTo(rectX + rectWidth - borderRadius, rectY);
    ctx!.quadraticCurveTo(
      rectX + rectWidth,
      rectY,
      rectX + rectWidth,
      rectY + borderRadius
    );
    ctx!.lineTo(rectX + rectWidth, rectY + rectHeight - borderRadius);
    ctx!.quadraticCurveTo(
      rectX + rectWidth,
      rectY + rectHeight,
      rectX + rectWidth - borderRadius,
      rectY + rectHeight
    );
    ctx!.lineTo(rectX + borderRadius, rectY + rectHeight);
    ctx!.quadraticCurveTo(
      rectX,
      rectY + rectHeight,
      rectX,
      rectY + rectHeight - borderRadius
    );
    ctx!.lineTo(rectX, rectY + borderRadius);
    ctx!.quadraticCurveTo(rectX, rectY, rectX + borderRadius, rectY);
    ctx!.closePath();
    ctx!.fill();

    // Define the text properties
    const date = new Date();
    const timeString = date.toLocaleTimeString(undefined, {
      hour12: false,
      timeStyle: "short",
    });
    const dateString = date.toLocaleDateString();
    const dayString = date.toLocaleDateString(undefined, { weekday: "long" });

    // Custom info
    const clientName = "client";
    const producerName = "producer";
    const farmName = "farm";

    ctx!.fillStyle = "yellowgreen";
    ctx!.font = "25px Arial";
    // ctx!.fontStretch= "semi-condensed"
    ctx!.textAlign = "left";
    ctx!.textBaseline = "top";

    const wrapText = (
      text: string,
      x: number,
      y: number,
      maxWidth: number,
      lineHeight: number
    ) => {
      const words = text.split(" ");
      let line = "";
      let lineCount = 0;

      for (let i = 0; i < words.length; i++) {
        const testLine = line + words[i] + " ";
        const metrics = ctx!.measureText(testLine);
        const testWidth = metrics.width;

        if (testWidth > maxWidth && i > 0) {
          ctx!.fillText(line, x, y + lineCount * lineHeight);
          line = words[i] + " ";
          lineCount++;
        } else {
          line = testLine;
        }
      }
      ctx!.fillText(line, x, y + lineCount * lineHeight);
      return y + (lineCount + 1) * lineHeight; // Return the next starting Y position
    };

    // Add the text with wrapping
    ctx!.font = "bold 22px Arial";
    let startY = rectY + 6;
    startY = wrapText(
      `${dateString} ${timeString}`,
      rectX + 10,
      startY,
      rectWidth - 5,
      20
    );

    const base64Image = canvas.toDataURL("image/webp");
    resolve(base64Image);
  });
