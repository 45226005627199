import React, {
  useState,
  useEffect,
  useCallback,
  forwardRef,
  ReactElement,
  Ref,
  useMemo,
  memo,
  Fragment,
} from "react";
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Autocomplete,
  Chip,
  TextField,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import {
  Button as ButtonAntd,
  ConfigProvider,
  Input,
  Popconfirm,
  Space,
  message,
} from "antd";
import {
  clientById,
  workflowCheckpointSendReportById,
  workflowEmptySendReportById,
  workflowFullSendReportById,
  // equipmentAcById,
  workflowSendReportById,
  workflowTruckSendReportById,
} from "../../apis/";
import {
  CloseOutlined,
  DeleteFilled,
  MailFilled,
  PhoneOutlined,
  PlusCircleOutlined,
  SendOutlined,
  WhatsAppOutlined,
} from "@ant-design/icons";
import { enqueueSnackbar } from "notistack";
import { COLOR_PRIMARY } from "../../constants";
import { v4 as uuidV4 } from "uuid";
import { useWidth } from "../../hooks";

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement<any, any>;
  },
  ref: Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type Wf = 'empty' | 'full' | 'checkpoint' | "truck" | "complete";

interface SendReportPropsI {
  id: number;
  open: boolean;
  handleClose: () => void;
  typeWf: Wf;
}

interface ValuesI {
  uuid: string;
  value: string;
}

export const SendReport = ({
  open,
  id,
  handleClose,
  typeWf,
}: SendReportPropsI) => {
  const width = useWidth();
  const [messageApi, contextHolder] = message.useMessage();
  const [receivers, setReceivers] = useState<ValuesI[]>([]);
  const [receiversNum, setReceiversNum] = useState<ValuesI[]>([]);
  const [isSending, setSending] = useState(false);
  // const [loading, setLoading] = useState(true);



  const handleSendReport = async () => {
    try {
      setSending(true);

      const obj = {
        mails:receivers
          .filter(el=> el.value)
          .filter(el=> el.value.length > 9)
          .map(el=>el.value),
        phones:receiversNum
        .filter(el=> el.value)
        .filter(el=> el.value.length > 9)
        .map(el=>el.value),
        id,
      };

      messageApi.open({
        key: "generatePdfKey1",
        type: "loading",
        content: "Enviando Reporte 📑",
        duration: 50,
      });
      switch (typeWf) {
        case "empty":
          const { data: dataSendEmpty } = await workflowEmptySendReportById(obj);
          // enqueueSnackbar(dataSendEmpty, { variant: "success" });
          setSending(false);
          break;
        case "full":
          const { data: dataSendFull } = await workflowFullSendReportById(obj);
          // enqueueSnackbar(dataSendFull, { variant: "success" });
          setSending(false);
          break;
        case "checkpoint":
          const { data: dataSendCheckpoint } = await workflowCheckpointSendReportById(obj);
          // enqueueSnackbar(dataSendCheckpoint, { variant: "success" });
          setSending(false);
          break;
        case "truck":
          const { data: dataSendTruck } = await workflowTruckSendReportById(obj);
          // enqueueSnackbar(dataSendTruck, { variant: "success" });
          setSending(false);
          break;
        default:
          const { data: dataSend } = await workflowSendReportById(obj);
          // enqueueSnackbar(dataSend, { variant: "success" });
          setSending(false);
          break;
      }
      messageApi.destroy("generatePdfKey1");
      messageApi.open({
        key: "generatePdfKey4",
        type: "success",
        content: "Enviado con éxito",
        duration: 2,
      });
    } catch (err: any) {
      setSending(false);
      messageApi.destroy("generatePdfKey1");
      messageApi.open({
        key: "generatePdfKey4",
        type: "error",
        content: "Comuníquese con el administrador",
        duration: 3,
      });
      enqueueSnackbar(err.toString(), { variant: "error" });
    }
  };

  const handleAddPhone = useCallback(() => {
    const obj = {
      uuid: uuidV4(),
      value: "",
    };
    receiversNum.push(obj);
    setReceiversNum([...receiversNum]);
  },[receiversNum]);

  const handleUpdatePhone = useCallback((uuid: string, value: string) => {
    setReceiversNum((rec) =>
      rec.map((el) => {
        if (el.uuid === uuid) return { ...el, value };
        return el;
      })
    );
  },[receiversNum]);

  const handleDeletePhone = useCallback((uuid: string) => {
    setReceiversNum((rec) => rec.filter((el) => el.uuid !== uuid));
  },[receiversNum]);

  const handleAddMail = useCallback(() => {
    const obj = {
      uuid: uuidV4(),
      value: "",
    };
    receivers.push(obj);
    setReceivers([...receivers]);
  },[receivers]);

  const handleUpdateMail = useCallback((uuid: string, value: string) => {
    setReceivers((rec) =>
      rec.map((el) => {
        if (el.uuid === uuid) return { ...el, value };
        return el;
      })
    );
  },[receivers]);

  const handleDeleteMail = useCallback((uuid: string) => {
    setReceivers((rec) => rec.filter((el) => el.uuid !== uuid));
  },[receivers]);

  return (
    <Fragment>
      {contextHolder}
      <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      fullScreen={width === "xs"}
      maxWidth="sm"
      fullWidth
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-send-report"
    >
      <DialogTitle
        sx={{
          bgcolor: COLOR_PRIMARY,
          color: "white",
          position: "relative",
          fontWeight: "bolder",
        }}
      >
        {"Envío de Reporte de Activo"}
        <ButtonAntd
          type="primary"
          danger
          style={{ position: "absolute", right: 5, top: 10 }}
          onClick={handleClose}
          icon={<CloseOutlined />}
        />
      </DialogTitle>
      <DialogContent sx={{ p:0 , position:"relative"}}>
        <Grid container spacing={0} >
          <Grid item xs={12} sm={6} sx={{ p: 1, bgcolor:"aliceblue", borderRadius:1}}>
            <Grid container 
              spacing={1} 
              alignContent="flex-start"
              alignItems="flex-start"
              sx={{ minHeight: 300, maxHeight: 300, height:300, overflowY:"auto"}}>
              <Grid item xs={12} sx={{position:"sticky", top:0, zIndex:600, bgcolor:"aliceblue"}}>
                <Space.Compact style={{ width: "100%" }}>
                  <ButtonAntd
                    style={{ width: "50%" }}
                    type="dashed"
                    onClick={handleAddPhone}
                    icon={<WhatsAppOutlined style={{color:"green"}}/>}
                  >
                    Celulares
                  </ButtonAntd>
                  <ButtonAntd
                    style={{ width: "50%" }}
                    onClick={handleAddPhone}
                    icon={<PlusCircleOutlined />}
                  >
                    Agregar
                  </ButtonAntd>
                </Space.Compact>
              </Grid>
              {receiversNum.map((el) => (
                <Grid item xs={12} key={el.uuid}>
                  <ItemComponent
                    onUpdate={handleUpdatePhone}
                    onDelete={handleDeletePhone}
                    placeholder="0988776655"
                    uuid={el.uuid}
                    name="Celular"
                    value={el.value}
                    maxLength={10}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} sx={{ p: 1, bgcolor:"ghostwhite", borderRadius:1}}>
            <Grid container 
              spacing={1} 
              alignContent="flex-start"
              alignItems="flex-start"
              sx={{ minHeight: 300, maxHeight: 300, height:300, overflowY:"auto"}}>
              <Grid item xs={12} sx={{position:"sticky", top:0, zIndex:600, bgcolor:"ghostwhite"}}>
                <Space.Compact style={{ width: "100%" }}>
                  <ButtonAntd
                    style={{ width: "50%" }}
                    type="dashed"
                    onClick={handleAddMail}
                    icon={<MailFilled style={{color:"blue"}}/>}
                  >
                    Correos
                  </ButtonAntd>
                  <ButtonAntd
                    style={{ width: "50%" }}
                    onClick={handleAddMail}
                    icon={<PlusCircleOutlined />}
                  >
                    Agregar
                  </ButtonAntd>
                </Space.Compact>
              </Grid>
              {receivers.map((el) => (
                <Grid item xs={12} key={el.uuid}>
                  <ItemComponent
                    onUpdate={handleUpdateMail}
                    onDelete={handleDeleteMail}
                    placeholder="correo@mail.com"
                    uuid={el.uuid}
                    name="Correo"
                    value={el.value}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ borderTop: "1px dashed gainsboro" }}>
        <ButtonAntd
          type="dashed"
          danger
          onClick={handleClose}
          icon={<CloseOutlined />}
        >
          Cancelar
        </ButtonAntd>
        <ConfigProvider
          theme={{
            components: {
              Popconfirm: {
                zIndexPopup: 1301,
              },
            },
          }}
        >
          <Popconfirm
            title="Enviar Reporte"
            description="Información Correcta?"
            onConfirm={handleSendReport}
            okText="Si"
            cancelText="No"
          >
            <ButtonAntd
              type="primary"
              icon={<SendOutlined />}
              // loading={isSending}
              disabled={isSending}
            >
              {isSending ? "Espere" : "Enviar"}
            </ButtonAntd>
          </Popconfirm>
        </ConfigProvider>
      </DialogActions>
    </Dialog>
    </Fragment>
    
  );
};

type names = "Celular" | "Correo";

interface ItemProps {
  uuid: string;
  onDelete: (uuid: string) => void;
  onUpdate: (uuid: string, value: string) => void;
  name: names;
  placeholder: string;
  value: string;
  maxLength?:number;
}

const ItemComponent = memo(({
  onDelete,
  name,
  onUpdate,
  placeholder,
  uuid,
  value: elvalue,
  maxLength,
}: ItemProps) => {
  const [value, setValue] = useState(elvalue);

  return (
    <Input
      addonBefore={name}
      value={value}
      maxLength={maxLength ? maxLength : 1000}
      onChange={(e) => setValue(e.target.value)}
      onBlur={(e) => {
        onUpdate(uuid, value?.trim() ?? "");
        setValue(e.target.value?.trim() ?? "");
      }}
      placeholder={placeholder}
      style={{ width: "100%" }}
      status={value.length < 10 ? "error" : ""}
      suffix={
        <ButtonAntd
          danger
          size="small"
          onClick={() => onDelete(uuid)}
          icon={<DeleteFilled />}
        />
      }
    />
  );
});
