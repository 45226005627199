import React from "react";
import {
  Navigate,
} from "react-router-dom";


const DatasAdd = React.lazy(() => import("../../../../../components/datas-upload/components/add/page"));
const DatasList = React.lazy(() => import("../../../../../components/datas-upload/components/list/page"));


export const datasUploadsRoutes = [
  {
    path: 'add',
    element: <DatasAdd />,
  },
  {
    path: 'edit',
    element: <DatasAdd />,
  },
  {
    path: 'list',
    element: <DatasList />,
  },
  {
    path: '',
    element: <Navigate replace to="/datas/uploads/list" />
  },
]