import React from "react";
import {
    Navigate,
} from "react-router-dom";

const ParishListComponent = React.lazy(() => import("../../../../../components/parish/components/list/page"));
const ParishAddComponent = React.lazy(() => import("../../../../../components/parish/components/add/page"));

export const parishRoute = [
    {
        path: 'list',
        element: <ParishListComponent />
    },
    {
        path: 'add',
        element: <ParishAddComponent />
    },
    {
        path: 'edit',
        element: <Navigate to="/models/parishes/add" />
    },
    {
        path: 'edit/:id',
        element: <ParishAddComponent />
    },
    {
        path: '',
        element: <Navigate replace to="/models/parishes/list" />
    },
];