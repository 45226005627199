import React from "react";
import {
  Navigate,
} from "react-router-dom";


const TruckAddComponent = React.lazy(() => import("../../../components/truck/components/add/page"));
const TruckListComponent = React.lazy(() => import("../../../components/truck/components/list/page"));


export const truckRoute = [
  {
      path: 'list',
      element: <TruckListComponent />,
  },
  {
      path: 'add',
      element: <TruckAddComponent />
  },
  {
      path: 'edit',
      element: <Navigate to="/truck/add" />
  },
  {
      path: 'edit/:id',
      element: <TruckAddComponent />
  },
  {
      path: '',
      element: <Navigate replace to="/truck/add" />
  },
];