export const LogoutSystem = ()=> {
    localStorage.removeItem(process.env.REACT_APP_TOKEN!);
    localStorage.removeItem(process.env.REACT_APP_TOKEN_OPTIONS!);
    localStorage.removeItem(process.env.REACT_APP_TOKEN_ROUTER!);
    window.location.replace('');
    clearIndexedDB();
}

const clearIndexedDB = async (): Promise<void> => {
    const dbs = await indexedDB.databases(); // Obtener las bases de datos
    dbs.forEach(({ name }) => {
        console.log(`Deleting database ${name}`);
      if (name) {
        const request = indexedDB.deleteDatabase(name);
        request.onsuccess = () => console.log(`Database ${name} deleted successfully`);
        request.onerror = (error) => console.error(`Failed to delete database ${name}`, error);
      }
    });
  };
  