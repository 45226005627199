import { useQuery } from "@tanstack/react-query";
import { modulesApi } from "./axios";
import {
    DataI,
    ListProformsI,
    PropsI,
    ServiceResponseI,
    MaterialI,
    PropsModulesI,
    CreateMaterialRequired,
    CreateMaterialI,
    ModuleI,
    ModuleTreeI,
    ModuleSimpleI,
    ModuleCreateRequiredI
} from '../../interfaces';
import {
    ValidateCurrentToken,
    LogoutSystem,
} from '../../helpers';
import { AxiosError } from 'axios';



const getModules = async ({
    pagination,
    sorting,
    globalFilter,
    all,
    parent,
}: PropsModulesI): Promise<ServiceResponseI<DataI<ModuleI>>> => {

    try {

        const validateToken = ValidateCurrentToken();

        if (!validateToken) throw new Error('TOKEN INVALIDO');

        const params = new URLSearchParams();

        params.append('page', (pagination!.pageIndex + 1).toString());
        params.append('limit', pagination!.pageSize.toString());
        globalFilter && params.append('search', globalFilter);
        sorting && params.append('sorting', JSON.stringify(sorting));
        all && params.append('all', all.toString());
        parent && params.append('parent', parent.toString());

        //headers: { 'Authorization': validateToken
        const { data } = await modulesApi.get<ServiceResponseI<DataI<ModuleI>>>('', { params, headers: { 'Authorization': validateToken } });
        return data;

    } catch (err) {
        const obj = (err as AxiosError).response;

        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }

        throw new Error((obj?.data as Error).message)
    }


}

export const modulePost = async (getForms: ModuleCreateRequiredI): Promise<ServiceResponseI<string>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await modulesApi.post<ServiceResponseI<string>>("", getForms, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const modulePut = async<T>(id: number, updateForms: T): Promise<ServiceResponseI<string>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await modulesApi.put<ServiceResponseI<string>>(`/${id}`, updateForms, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const moduleById = async (id: number): Promise<ServiceResponseI<ModuleI>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await modulesApi.get<ServiceResponseI<ModuleI>>(`/${id}`, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const modulesByParentId = async (id: number): Promise<ServiceResponseI<ModuleI[]>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');


        const { data } = await modulesApi.get<ServiceResponseI<ModuleI[]>>(`/${id}`);
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        
    }
}



export const moduleDelete = async<T>(id: number): Promise<ServiceResponseI<string>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await modulesApi.delete<ServiceResponseI<string>>(`/${id}`, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const getAllModulesParentChildren = async (typeModule?: number): Promise<ServiceResponseI<ModuleTreeI[]>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');

        if (typeModule) {
            const { data } = await modulesApi.get<ServiceResponseI<ModuleTreeI[]>>(`/all-parent-children-by-type/${typeModule}`, { headers: { 'Authorization': validateToken } });
            return data;
        }

        const { data } = await modulesApi.get<ServiceResponseI<ModuleTreeI[]>>(`/all-parent-children`, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const getAllModulesParent = async (): Promise<ServiceResponseI<ModuleSimpleI[]>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');

        
        const { data } = await modulesApi.get<ServiceResponseI<ModuleSimpleI[]>>(`/all-parent`, { headers: { 'Authorization': validateToken } });
        
        return data;

    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const getAllModulesChildren = async (): Promise<ServiceResponseI<ModuleSimpleI[]>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');

        
        const { data } = await modulesApi.get<ServiceResponseI<ModuleSimpleI[]>>(`/all-children`, { headers: { 'Authorization': validateToken } });
        
        return data;
        
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const useModules = ({
    sorting,
    pagination,
    globalFilter,
    all,
    parent,
}: PropsModulesI) => {


    const moduleQuery = useQuery(
        ["modules", { pagination, sorting, globalFilter, all, parent }],
        () => getModules({ pagination, sorting, globalFilter, all, parent  }),
        {
            refetchOnWindowFocus: false,
            keepPreviousData: true
        }
    );






    return {
        moduleQuery,
    };
}