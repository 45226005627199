import { useEffect, useState } from "react";


import { enqueueSnackbar } from "notistack";
import { Box } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import FondoDashboard from "../images/bg-dashboard.jpg";

export const Dashboard = () => {
  const navigate = useNavigate();

  const [data, setData] = useState<any>({
    total: 0,
    equipments: [],
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      setLoading(false);
    } catch (err: any) {
      enqueueSnackbar(err.toString(), { variant: "error" });
      console.log(err);
    }
  };


  return (
    <div
      style={{
        position: "relative",
        height: "100%",
        width: "100%",
        maxWidth: "100%",
        overflowX: "hidden",
        overflowY: "hidden",
        backgroundImage: `url(${FondoDashboard})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        backgroundAttachment: "fixed",
        backgroundSize: "cover",
      }}
    >
    </div>
  );
};

